import React, { useRef, useState } from 'react';

import { TabBody } from './TabBody';
import { ReactComponent as LoaderImg } from '../../../../public/icons/loaderAnimation.svg';
import Button from '../../atoms/Button/Button';
import { ELibraryCardType } from '../../atoms/LibraryCard';
import { TNewsCardProps } from '../../atoms/NewsCard/NewsCard';
import { TabsWithSelect } from '../../atoms/Tabs';
import Container from '../../sections/Container/Container';

type TLibraryCardRow = {
  tabTitles: Record<ELibraryCardType, string>;
  tabPosts: { title: string; posts: TNewsCardProps[] }[];
  isLoading?: boolean;
  buttons?: React.ComponentProps<typeof Button>[];
};

export const LibraryCardRow: React.FC<TLibraryCardRow> = ({
  tabTitles,
  tabPosts,
  isLoading,
  buttons,
}) => {
  const [tab, setTab] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = (index: number): void => {
    setTab(index);
  };

  const tabsTitles = Object.values(tabTitles).map(title => ({ title }));

  const currentTabPosts = tabPosts[tab]?.posts || [];
  const wrapperRef = useRef<HTMLDivElement>(null);
  return (
    <section>
      <Container>
        <div ref={containerRef}>
          {isLoading ? (
            <div className='h-full w-full bg-surface-100'>
              <LoaderImg className='h-[1280px] w-full tablet:h-[670px]' />
            </div>
          ) : (
            <>
              <div
                className='mx-auto mb-6 flex w-full max-w-[77rem] justify-center'
                ref={wrapperRef}
              >
                <TabsWithSelect
                  tabs={tabsTitles}
                  activeIndex={tab}
                  onClick={handleButtonClick}
                  wrapperRef={wrapperRef}
                />
              </div>
              <TabBody buttons={buttons} tabPosts={currentTabPosts} />
            </>
          )}
        </div>
      </Container>
    </section>
  );
};
