import clsx from 'clsx';
import React from 'react';

import { ImageWithPlaceholder } from '../../../atoms/ImageWithPlaceholder';

import type { ImageProps } from 'next/image';

type TSideImageProps = {
  image?: ImageProps | null;
  className?: string;
};

export const SideImage: React.FC<TSideImageProps> = ({ image, className }) => {
  if (!image) {
    return null;
  }

  return (
    <div className={clsx('relative flex-1', className)}>
      <ImageWithPlaceholder
        {...image}
        className='h-auto w-full object-cover object-center md:absolute md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2'
      />
    </div>
  );
};
