import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { ENewsCardSize } from '../NewsCard';

export type THorizontalNewsCardCoverPart = {
  cover: {
    src?: string;
    alt?: string;
  } | null;
  cardSize?: ENewsCardSize;
  link: LinkProps;
};

const HorizontalNewsCardCoverPart: React.FC<
  THorizontalNewsCardCoverPart
> = props => {
  const { cover, cardSize = ENewsCardSize.M, link } = props;

  if (!cover || !link.href || !cover.src) {
    return null;
  }

  return (
    <Link
      className={clsx(
        'min-w-[4.5rem] overflow-hidden',
        cardSize === ENewsCardSize.M && 'h-[4.5rem] w-[4.5rem] rounded-2xl',
        cardSize === ENewsCardSize.Xl &&
          'h-[11.438rem] w-full max-w-full rounded-[1.5rem] md:h-[20.313rem] md:rounded-[2rem] lg:h-[24.563rem] xl:h-[16.25rem] xl:w-1/2 2xl:h-[20.25rem]',
      )}
      {...link}
    >
      <img
        className={clsx(
          'h-full w-full object-cover',
          cardSize === ENewsCardSize.Xl && 'object-left',
        )}
        //Comes with API, not stored where all our pictures are
        alt={cover.alt}
        loading='lazy'
        src={cover.src}
        width={832}
        height={350}
      />
    </Link>
  );
};

export default HorizontalNewsCardCoverPart;
