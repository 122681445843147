import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { ENewsCardSize } from '../NewsCard';

export type TNewsCardCoverPart = {
  cover: {
    src?: string;
    alt?: string;
  } | null;
  cardSize?: ENewsCardSize;
  link: LinkProps;
};

const NewsCardCoverPart: React.FC<TNewsCardCoverPart> = props => {
  const { cover, cardSize = ENewsCardSize.S, link } = props;

  if (!cover || !link.href || !cover?.src) {
    return null;
  }

  return (
    <Link className='block' {...link}>
      <img
        width={343}
        height={244}
        className={clsx(
          'h-[12.125rem] w-full max-w-full rounded-3xl object-cover object-left',
          cardSize === ENewsCardSize.S &&
            'md:h-[10.438rem] lg:h-[12.688rem] xl:h-[8.303rem]  2xl:h-[10.625rem]',
          cardSize === ENewsCardSize.M &&
            'md:h-[10.438rem] lg:h-[12.688rem] xl:h-[11.25rem] 2xl:h-[14.375rem]',
          cardSize === ENewsCardSize.L &&
            'md:h-[10.438rem] lg:h-[12.688rem] xl:h-[17.188rem] 2xl:h-[21.875rem]',
          cardSize === ENewsCardSize.Xl &&
            'md:h-[16rem] lg:h-[19.329rem] xl:h-[17.188rem] 2xl:h-[21.875rem] ',
        )}
        //Comes with API, not stored where all our pictures are
        alt={cover.alt}
        src={cover.src}
        loading='lazy'
      />
    </Link>
  );
};

export default NewsCardCoverPart;
