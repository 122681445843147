import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import { ECardWithLogoContentAlign, ECardWithLogoTheme } from './constants';
import { Button, TButtonProps } from '../Button';
import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../Eyebrow';
import { Icon } from '../Icon';
import { EIconBottomPadding, EIconSize } from '../Icon/Icon.constants';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import { TRichTextContent, RichText } from '../RichText';
import { TitlePart } from '../TitlePart';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  ETitlePartsTheme,
} from '../TitlePart/TitlePart.constants';

export type TCardWithLogo = {
  topLogo?: ImageProps | null;
  eyebrows?: {
    eyebrow: string;
  }[];
  innerIcon?: ImageProps | null;
  image?: ImageProps | null;
  title?: string;
  description?: TRichTextContent;
  backgroundColor?: string;
  theme?: `${ECardWithLogoTheme}`;
  contentAlign?: `${ECardWithLogoContentAlign}`;
  button?: TButtonProps;
};

const CardWithLogo: React.FC<TCardWithLogo> = props => {
  const {
    title,
    topLogo,
    eyebrows,
    innerIcon,
    image,
    description,
    theme = ECardWithLogoTheme.CardWithLogo1,
    contentAlign = ECardWithLogoContentAlign.Center,
    backgroundColor,
    button,
  } = props;

  return (
    <div
      className={clsx('relative h-full', {
        'pt-[1.719rem]': topLogo,
      })}
    >
      <div
        className={clsx('flex h-full flex-col gap-2 px-8 pb-6 pt-10', {
          'items-center': contentAlign === ECardWithLogoContentAlign.Center,
          'items-start': contentAlign === ECardWithLogoContentAlign.Left,
          'rounded-[2rem] border border-control-200':
            theme === ECardWithLogoTheme.CardWithLogo1,
        })}
      >
        {topLogo && (
          <div
            style={
              backgroundColor
                ? {
                    backgroundColor,
                  }
                : {}
            }
            className='absolute left-1/2 top-0 h-[3.5rem] min-w-[3.5rem] max-w-[16rem] -translate-x-1/2 px-1'
          >
            <ImageWithPlaceholder
              className='block h-full object-contain'
              {...topLogo}
            />
          </div>
        )}
        <div className='flex flex-col gap-1'>
          {eyebrows && (
            <div className='flex justify-center gap-4'>
              {eyebrows.map((item, key) =>
                item.eyebrow ? (
                  <Eyebrow
                    variant={EEyebrowVariant.TextTracking}
                    size={EEyebrowSize.Medium}
                    key={key}
                  >
                    {item.eyebrow}
                  </Eyebrow>
                ) : null,
              )}
            </div>
          )}
          {(innerIcon || image) && (
            <div
              className={clsx('flex items-center  gap-4', {
                'justify-center':
                  contentAlign === ECardWithLogoContentAlign.Center,
                'justify-start':
                  contentAlign === ECardWithLogoContentAlign.Left,
              })}
            >
              {innerIcon && (
                <Icon
                  size={EIconSize.Xl}
                  bottomPadding={EIconBottomPadding.No}
                  icon={innerIcon}
                />
              )}
              {image && (
                <ImageWithPlaceholder
                  className='h-12 w-auto max-w-[21.438rem] object-cover'
                  height={48}
                  width={343}
                  {...image}
                />
              )}
            </div>
          )}
        </div>
        {title && (
          <TitlePart
            theme={ETitlePartsTheme.Dark}
            size={ETitlePartsSize.M}
            position={
              contentAlign === ECardWithLogoContentAlign.Center
                ? ETitlePartsPosition.Center
                : ETitlePartsPosition.Left
            }
          >
            {title}
          </TitlePart>
        )}
        {description && (
          <RichText
            className={clsx(
              'text-base font-normal leading-mega-loose text-interface-800',
              {
                'text-center':
                  contentAlign === ECardWithLogoContentAlign.Center,
                'text-start': contentAlign === ECardWithLogoContentAlign.Left,
              },
            )}
            content={description}
          />
        )}
        {button && (
          <div className='mt-auto flex flex-row flex-wrap items-start gap-2.5'>
            <Button {...button}>{button.children}</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardWithLogo;
