import React from 'react';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CardWithLogo, TCardWithLogo } from '../../../atoms/CardWithLogo';
import { EContainerVariant, Container } from '../../../sections/Container';

export type TCardWithLogoRowProps = {
  cards: TCardWithLogo[];
};

export const YesScrollRowComponent: React.FC<TCardWithLogoRowProps> = props => {
  const { cards } = props;

  return (
    <section>
      <Container variant={EContainerVariant.Full} overflowHidden>
        <Swiper
          modules={[Mousewheel]}
          spaceBetween={16}
          slidesPerView={'auto'}
          className='flex overflow-visible'
          wrapperClass={'flex'}
          mousewheel={{ releaseOnEdges: true, forceToAxis: true }}
        >
          {cards.map((item, key) => (
            <SwiperSlide
              className='h-[unset] shrink-0 grow-0 basis-[calc(100vw/1.22)] md:basis-[calc(100vw/1.96)] lg:basis-[calc(100vw/1.9225)]  xl:basis-[calc(100vw/3.2)] 2xl:basis-[calc(100vw/3.34)]'
              key={key}
            >
              <CardWithLogo {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};
