import { baseTailwindConfig } from '@front/shared';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React, { useRef, useState } from 'react';
import { useMedia } from 'react-use';

import { EPartnersWidgetTabsIconPosition } from './PartnersWidget.constants';
import {
  PartnersWidgetCard,
  TPartnersWidgetCardData,
} from './PartnersWidgetCard';
import {
  PartnersWidgetTabs,
  PARTNERS_WIDGET_ALL_TAB_VALUE,
} from './PartnersWidgetTabs';
import { Container } from '../../sections/Container';

export { EPartnersWidgetTabsIconPosition };
export type { TPartnersWidgetCardData };
export type TPartnersWidgetTab = {
  text: string;
  iconProps?: ImageProps | null;
  cards: TPartnersWidgetCardData[];
};

export type TPartnersWidgetProps = {
  tabs: TPartnersWidgetTab[];
  showTabs?: boolean;
  tabsIconPosition?: EPartnersWidgetTabsIconPosition;
  visibleThreshold?: number;
  allTabLabel?: string;
  moreLabel?: string;
  tabsStickyThreshold?: number;
};

export const PartnersWidget: React.FC<TPartnersWidgetProps> = props => {
  const {
    tabs = [],
    visibleThreshold,
    allTabLabel,
    moreLabel,
    tabsStickyThreshold,
    showTabs = true,
    tabsIconPosition,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isMatchesMdBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.md})`,
    true,
  );

  const defaultSelectedTabId = allTabLabel
    ? PARTNERS_WIDGET_ALL_TAB_VALUE
    : tabs?.[0].text;

  const [selectedTabId, setSelectedTabId] =
    useState<string>(defaultSelectedTabId);
  const [expanded, setExpanded] = useState(false);

  const selectedTab = selectedTabId
    ? (tabs || []).find(tab => tab.text === selectedTabId)
    : null;

  let cards: TPartnersWidgetCardData[] = selectedTab
    ? selectedTab?.cards || []
    : [];

  if (selectedTabId === PARTNERS_WIDGET_ALL_TAB_VALUE) {
    tabs.forEach(tab => {
      cards = cards.concat(tab.cards || []);
    });
  }

  const limit =
    isMatchesMdBreakpoint && visibleThreshold ? visibleThreshold : 1000;
  const hasMore = cards.length > limit;
  const hiddenCount = hasMore ? cards.length - limit + 1 : 0;

  const visibleCards =
    cards.length > limit && !expanded ? cards.slice(0, limit - 1) : cards;

  const onChangeTab = (value?: string): void => {
    setSelectedTabId(value || defaultSelectedTabId);
    setExpanded(false);
  };

  const onClickShowMore = (): void => {
    setExpanded(true);
  };

  return (
    <Container>
      <div className='flex flex-col gap-12' ref={wrapperRef}>
        {showTabs && tabs?.length > 1 && (
          <PartnersWidgetTabs
            tabs={tabs}
            selectedTabId={selectedTabId}
            allTabLabel={allTabLabel}
            tabsIconPosition={tabsIconPosition}
            tabsStickyThreshold={tabsStickyThreshold}
            wrapperRef={wrapperRef}
            onChangeTab={onChangeTab}
          />
        )}

        <div
          ref={containerRef}
          className={clsx(
            'scrollbar-hidden -mx-4 -mt-3 flex gap-x-4 gap-y-8 overflow-x-scroll px-4 pt-3 md:mr-0 md:pr-0',
            'md:flex-wrap md:justify-center md:*:w-[calc(50%_-_8px)] xl:*:w-[calc(100%/3_-_32px/3)] 2xl:*:w-[calc(25%_-_12px)]',
            { 'justify-center': cards?.length === 1 },
          )}
        >
          {visibleCards.map((card, index) => (
            <PartnersWidgetCard key={index} card={card} />
          ))}

          {hasMore && !expanded && (
            <button onClick={onClickShowMore}>
              <div className='relative flex h-[18.75rem] flex-col items-center justify-center rounded-2xl bg-control-100 px-10 hover:bg-control-150'>
                <div className='flex flex-row items-center'>
                  <span className='whitespace-nowrap text-lg font-medium leading-normal text-control-1000'>
                    {moreLabel
                      ? moreLabel.replace('{{count}}', `${hiddenCount}`)
                      : `And ${hiddenCount} more`}
                  </span>
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </Container>
  );
};
