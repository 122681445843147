import React, { useEffect, useRef, useState } from 'react';

import { TStoryCard } from './CardWithStories.constants';
import { SwiperSlider } from './components/SwiperSlider/SwiperSlider';
import WalletCard, {
  EWalletCardTheme,
  TWalletCardProps,
} from './components/WalletCard/WalletCard';
import baseTailwindConfig from '../../../../tailwind.config.base';
import { useBetterMediaQuery } from '../../../hooks/useBetterMediaQuery';
import { Story } from '../../atoms/Story';
import { StoryCTA } from '../../atoms/StoryCTA';
import { TStoryCtaProps } from '../../atoms/StoryCTA/StoryCTA';

export type TCardWithStoriesProps = {
  stories: TStoryCard[];
  cards: TWalletCardProps[];
  button?: TStoryCtaProps;
};

const CardWithStories: React.FC<TCardWithStoriesProps> = props => {
  const { cards = [], stories = [], button } = props;

  const isMd = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.md})`,
  );

  const [highlightedCards, setHighlightedCards] = useState<number[]>([]);
  const [activeStory, setActiveStory] = useState<number>();

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContainerRef.current && isMd) {
      const container = scrollContainerRef.current;
      const isRTL = getComputedStyle(container).direction === 'rtl';

      const centerPosition =
        (container.scrollWidth - container.clientWidth) / 2;
      if (isRTL) {
        container.scrollLeft = -centerPosition; // Negative scroll position for RTL
      } else {
        container.scrollLeft = centerPosition;
      }
    }
  }, [isMd]);

  if (!stories?.length) {
    return null;
  }

  const handleMouseLeave = (): void => {
    setHighlightedCards([]);
    setActiveStory(-1);
  };

  const handleClick = (index: number): void => {
    if (typeof stories[index] !== 'undefined') {
      setHighlightedCards(stories[index].highlightedCards || []);
    }
    setActiveStory(index);
  };
  return (
    <div className='flex flex-col-reverse flex-wrap items-center justify-center gap-4 overflow-hidden xl:flex-row xl:flex-nowrap xl:items-stretch'>
      <div
        ref={scrollContainerRef}
        className='max-w-full md:overflow-x-auto xl:overflow-x-visible'
      >
        {isMd ? (
          <div className='hidden min-w-[51.5rem] grid-cols-5 gap-4 overflow-x-auto px-4 md:grid xl:px-0 xl:max-2xl:min-w-[41rem] xl:max-2xl:grid-cols-4'>
            {stories.map((story, index) => {
              return (
                <Story
                  key={`${story.title}-${index}`}
                  {...story}
                  onClick={() => {
                    handleClick(index);
                  }}
                  onMouseEnter={() => {
                    handleClick(index);
                  }}
                  onMouseLeave={handleMouseLeave}
                  selected={activeStory === index}
                />
              );
            })}
            {button && <StoryCTA {...button} />}
          </div>
        ) : (
          <SwiperSlider list={stories} onClick={handleClick} button={button} />
        )}
      </div>

      <div className='flex flex-wrap justify-center gap-4 px-4 md:flex-nowrap xl:basis-80 xl:flex-wrap xl:justify-start xl:px-0 2xl:basis-[24.5rem]'>
        {cards.map((card, index) => {
          return (
            <WalletCard
              key={`${card.title}-${index}`}
              {...card}
              activeTheme={
                index === 0
                  ? EWalletCardTheme.Accent500
                  : EWalletCardTheme.Accent550
              }
              highlighted={highlightedCards.includes(index)}
            />
          );
        })}
      </div>
    </div>
  );
};
export default CardWithStories;
