import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import React, { ComponentProps } from 'react';
import ReactPaginate from 'react-paginate';

import type { BlogArticle } from '@shared/master-types';

type TNewsWebsiteBlogBlockTag = {
  tagId: string;
  text: string;
  slug: string;
  linkProps?: LinkProps;
};

type TNewsWebsiteBlogBlockProps = {
  docs: BlogArticle[];
  selectedTagId: string;
  tagMenu?: TNewsWebsiteBlogBlockTag[];
  isLoading?: boolean;
  totalPages?: number;
  page?: number;
  handlePageChange?: ComponentProps<typeof ReactPaginate>['onPageChange'];
};

export const NewsWebsiteBlogBlock: React.FC<TNewsWebsiteBlogBlockProps> = ({
  docs,
  selectedTagId,
  tagMenu,
  isLoading,
  totalPages,
  page,
  handlePageChange,
}) => {
  if (isLoading) {
    return (
      <p className='w-full max-w-[39.5rem] animate-pulse rounded-xl bg-surface-200 p-8 text-center text-lg font-normal text-interface-1000' />
    );
  }

  return (
    <div className='flex flex-col gap-8'>
      <div className='scrollbar-hidden flex snap-x snap-mandatory justify-start gap-3 overflow-x-auto'>
        {tagMenu?.map(menuItem => (
          <li key={menuItem.text} className='list-none whitespace-nowrap'>
            <Link
              className={clsx(
                'flex h-full snap-center items-center rounded-xl px-6 py-3 text-base font-medium leading-relaxed transition hover:bg-brand-500 hover:text-white',
                {
                  'bg-brand-500 text-white': selectedTagId === menuItem.tagId,
                  'bg-control-100 text-control-950':
                    selectedTagId !== menuItem.tagId,
                },
              )}
              href={menuItem.slug}
              {...menuItem.linkProps}
            >
              {menuItem.text}
            </Link>
          </li>
        ))}
      </div>
      <div>
        <div>[RENDER NEWS CARDS HERE]</div>
        {docs.map(doc => (
          <div key={doc.id}>{doc.title}</div>
        ))}
      </div>
      {!!(totalPages && page && handlePageChange) && (
        <ReactPaginate
          pageCount={totalPages || 0}
          breakLabel='...'
          previousLabel={null}
          nextLabel={null}
          forcePage={page - 1}
          activeLinkClassName='bg-brand-500 hover:bg-brand-550 text-brand-50'
          containerClassName='flex justify-start gap-2 text-sm font-medium leading-normal -ml-2'
          pageLinkClassName='flex items-center justify-center h-8 w-10'
          pageClassName='bg-control-100 text-control-950 hover:bg-brand-550 hover:text-brand-50 overflow-hidden rounded-xl transition'
          breakLinkClassName={clsx(
            'flex h-8 w-10 items-center justify-center',
            page === 1 &&
              'rounded-xl bg-control-100 transition hover:bg-brand-550',
          )}
          onPageChange={handlePageChange}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
        />
      )}
    </div>
  );
};
