import clsx from 'clsx';
import React from 'react';

import HorizontalNewsCardBottomPart from './HorizontalNewsCardBottomPart';
import HorizontalNewsCardContentPart from './HorizontalNewsCardContentPart';
import HorizontalNewsCardCoverPart from './HorizontalNewsCardCoverPart';
import { ENewsCardSize, TNewsCardProps } from '../NewsCard';

const HorizontalNewsCard: React.FC<TNewsCardProps> = props => {
  const {
    description,
    title,
    cover,
    date,
    tags,
    cardSize = ENewsCardSize.M,
    link,
  } = props;

  const renderLink = link?.href;
  if (!renderLink) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex w-full ',
        cardSize === ENewsCardSize.M &&
          'max-w-[21.438rem] flex-row gap-4 md:max-w-[18.5rem] lg:max-w-[22.5rem] xl:max-w-[18.5rem] 2xl:max-w-[25.5rem]',
        cardSize === ENewsCardSize.Xl && 'flex-col gap-4 xl:flex-row',
      )}
    >
      <HorizontalNewsCardCoverPart
        link={link}
        cover={cover}
        cardSize={cardSize}
      />
      <div
        className={clsx(
          'flex flex-col gap-2',
          cardSize === ENewsCardSize.Xl && 'xl:w-1/2',
        )}
      >
        <HorizontalNewsCardContentPart
          cardSize={cardSize}
          link={link}
          title={title}
          description={description}
        />
        <HorizontalNewsCardBottomPart tags={tags} date={date} />
      </div>
    </div>
  );
};

export default HorizontalNewsCard;
