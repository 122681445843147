import clsx from 'clsx';
import React from 'react';

import {
  ETitleCardBasicAlign,
  ETitleCardBasicPadding,
  TitleCardBasic,
} from '../../atoms/TitleCardBasic';
import { Container } from '../../sections/Container';

export type TTitleBasicCardsProps = {
  cards: React.ComponentPropsWithoutRef<typeof TitleCardBasic>[];
  padding: ETitleCardBasicPadding;
  align: ETitleCardBasicAlign;
};

const TitleBasicCards: React.FC<TTitleBasicCardsProps> = props => {
  const { padding, align, cards = [] } = props;

  return (
    <section>
      <Container>
        <div
          className={clsx(
            'flex flex-col gap-4 md:flex-row md:flex-wrap md:gap-x-16 md:gap-y-4',
            {
              'md:justify-center': align === ETitleCardBasicAlign.Center,
              'justify-start': align === ETitleCardBasicAlign.Left,
              'pb-12 md:pb-16': padding === ETitleCardBasicPadding.Yes,
            },
          )}
        >
          {cards.map((card, index) => (
            <TitleCardBasic key={index} {...card} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default TitleBasicCards;
