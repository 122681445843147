import clsx from 'clsx';
import React from 'react';

import { ESchemeSwipeVariant } from './SchemeSwipe.constants';

export type TSchemeSwipeProps = {
  swipeText: string;
  swipeTheme?: ESchemeSwipeVariant;
};

export const SchemeSwipe: React.FC<TSchemeSwipeProps> = props => {
  const { swipeText, swipeTheme = ESchemeSwipeVariant.Light } = props;

  return (
    <div
      className={clsx('-mt-3 rounded-b-2xl pt-3 md:hidden md:rounded-none', {
        'bg-light-surface-100': swipeTheme === ESchemeSwipeVariant.Light,
        'bg-interface-50': swipeTheme === ESchemeSwipeVariant.White,
      })}
    >
      <p className='p-2  text-center text-sm font-medium leading-[1.05rem] text-light-interface-800'>
        {'< ' + swipeText + ' >'}
      </p>
    </div>
  );
};
