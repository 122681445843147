import React from 'react';

import { ReactComponent as LoaderImg } from '../../../../public/icons/loaderAnimation.svg';
import { HorizontalNewsCard } from '../../atoms/HorizontalNewsCard';
import { ENewsCardSize, TNewsCardProps } from '../../atoms/NewsCard';
import { Container } from '../../sections/Container';
import { NewsCardRow } from '../NewsCardRow';

type TNewsCardRowWp = {
  newsItems: TNewsCardProps[];
  horizontalNewsItem: TNewsCardProps;
  isLoading?: boolean;
};

export const NewsCardRowWP: React.FC<TNewsCardRowWp> = ({
  newsItems,
  horizontalNewsItem,
  isLoading,
}) => {
  return (
    <section>
      <Container>
        <div className='flex flex-col gap-12'>
          {isLoading ? (
            <div className='h-full w-full'>
              <LoaderImg />
            </div>
          ) : (
            <>
              <HorizontalNewsCard
                {...horizontalNewsItem}
                cardSize={ENewsCardSize.Xl}
              />
              <NewsCardRow cardSize={ENewsCardSize.M} newsItems={newsItems} />
            </>
          )}
        </div>
      </Container>
    </section>
  );
};
