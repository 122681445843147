import { parseISO, isValid, format } from 'date-fns';

export default function buildNumericDateFormat(date: string): string {
  let parsedDate: Date;

  parsedDate = parseISO(date);
  if (!isValid(parsedDate)) {
    parsedDate = new Date(date);
    if (!isValid(parsedDate)) {
      return date;
    }
  }

  return format(parsedDate, 'dd.MM.yyyy');
}
