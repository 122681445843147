import Image, { ImageProps } from 'next/image';
import React from 'react';

import { Container } from '../../sections/Container';

export type TListOfTitlesProps = {
  list: {
    title: string;
    logo?: ImageProps | null;
  }[];
};

const ListOfTitles: React.FC<TListOfTitlesProps> = props => {
  const { list } = props;

  return (
    <Container>
      <div className='flex flex-wrap justify-center gap-6 2xl:gap-x-16'>
        {list.map((item, index) => (
          <div key={index} className='flex w-full max-w-fit items-center gap-2'>
            {item.logo && (
              <Image
                {...item.logo}
                className='box-content h-6 w-6 shrink-0 object-contain object-center p-1 2xl:p-0'
              />
            )}
            {item.title && (
              <p className='py-[0.313rem] text-lg font-medium leading-normal text-interface-1000 2xl:py-0'>
                {item.title}
              </p>
            )}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default ListOfTitles;
