export enum ETitleCardBasicAlign {
  Left = 'left',
  Center = 'center',
}

export enum ETitleCardBasicPadding {
  No = 'no',
  Yes = 'yes',
}

export enum ETitleCardBasicTruncateText {
  No = 'no',
  Yes = 'yes',
}

export enum ETitleCardBasicAlignIcon {
  Top = 'top',
  Center = 'center',
}
