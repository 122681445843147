import clsx from 'clsx';
import { cva } from 'cva';
import React from 'react';

import { EGridCardImagePaddings } from './GridCard.constants';
import { EIconBottomPadding } from '../../atoms/Icon/Icon.constants';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import {
  EDefaultCardV2ImagePosition,
  EDefaultCardV2TopIconSize,
} from '../DefaultCardV2';
import { Description, Title, TopIcon } from '../DefaultCardV2/components';

import type { ImageProps } from 'next/image';

const cardWrapperCVA = cva('', {
  variants: {},
});

const cardImageCva = cva('h-auto w-full object-contain object-bottom', {
  variants: {
    imagePaddings: {
      [EGridCardImagePaddings.HorizontalAndBottom]:
        'tablet:px-8 px-6 desktop:px-8',
      [EGridCardImagePaddings.None]: '',
    },
    imagePosition: {
      [EDefaultCardV2ImagePosition.Bottom]: '',
      [EDefaultCardV2ImagePosition.Top]: '',
    },
  },
  compoundVariants: [
    {
      imagePaddings: EGridCardImagePaddings.HorizontalAndBottom,
      imagePosition: EDefaultCardV2ImagePosition.Bottom,
      className: 'pb-6 tablet:pb-8',
    },
    {
      imagePaddings: EGridCardImagePaddings.HorizontalAndBottom,
      imagePosition: EDefaultCardV2ImagePosition.Top,
      className: 'pt-6 tablet:pt-8',
    },
  ],
});

export type TGridCardProps = {
  image?: ImageProps | null;
  title: React.JSX.Element | string | null;
  imagePaddings?: EGridCardImagePaddings;
  imagePosition?: EDefaultCardV2ImagePosition;
  topIcon?: ImageProps | null;
  description?: React.JSX.Element | string | null;
  className?: string;
  cardImageClassName?: string;
};

export const GridCard: React.FC<TGridCardProps> = props => {
  const {
    image,
    title,
    description,
    className,
    cardImageClassName,
    imagePaddings = EGridCardImagePaddings.None,
    imagePosition = EDefaultCardV2ImagePosition.Bottom,
    topIcon,
  } = props;

  return (
    <div className={cardWrapperCVA({ className })}>
      <div
        className={clsx(
          'relative flex h-full w-full flex-1 justify-between overflow-hidden rounded-3xl',
          {
            'flex-col-reverse':
              imagePosition === EDefaultCardV2ImagePosition.Top,
            'flex-col': imagePosition === EDefaultCardV2ImagePosition.Bottom,
          },
        )}
      >
        {(title || description) && (
          <div className='relative flex flex-1 flex-col p-6 tablet:p-8'>
            <div className='flex flex-1 flex-col'>
              {topIcon && (
                <div className='flex-1'>
                  <TopIcon
                    topIcon={topIcon}
                    topIconSize={EDefaultCardV2TopIconSize.Xl1}
                    bottomPadding={EIconBottomPadding.Yes}
                  />
                </div>
              )}

              <Title
                title={title}
                className={clsx(description && 'mb-4', 'z-10')}
              />

              <Description
                className='z-10 text-base leading-extra-loose text-interface-700'
                description={description}
              />
            </div>
          </div>
        )}

        {image && (
          <ImageWithPlaceholder
            {...image}
            className={cardImageCva({
              imagePaddings,
              imagePosition,
              className: cardImageClassName,
            })}
          />
        )}
      </div>
    </div>
  );
};
