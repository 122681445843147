import clsx from 'clsx';
import React from 'react';

import { ReactComponent as IconArrowUp } from '../../../../public/icons/arrow-up-no-frame.svg';
import {
  Button,
  TButtonProps,
  EButtonVariant,
  EButtonTheme,
  EButtonSize,
} from '../Button';

type TMenuButtonCommonProps = {
  isOpen?: boolean;
  isActive?: boolean;
  hideArrow: boolean;
};
export type TMenuButtonProps = TButtonProps & TMenuButtonCommonProps;

const MenuButton: React.FC<TMenuButtonProps> = props => {
  const { children, hideArrow, isOpen, isActive, ...buttonProps } = props;

  return (
    <Button
      variant={EButtonVariant.Text}
      rounded
      theme={EButtonTheme.Secondary}
      size={EButtonSize.Medium}
      className={clsx(
        'hover:bg-header-button-hover flex h-10 whitespace-nowrap rounded-full px-4 py-2.5',
        isActive && 'bg-header-button-hover',
      )}
      {...buttonProps}
    >
      {children}
      {!hideArrow && (
        <IconArrowUp
          width={10}
          height={6}
          className={clsx(
            'transition',
            !isOpen && 'translate-y-[-2px]  rotate-180',
          )}
        />
      )}
    </Button>
  );
};

export default MenuButton;
