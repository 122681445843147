import { baseTailwindConfig } from '@front/shared';
import Link from 'next/link';
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { TAction } from '../../../types';
import { EVideoSize, Video } from '../../atoms/Video';
import { Container, EContainerVariant } from '../../sections/Container';

import type { VideoCollection } from '@shared/master-types';

export type TVideosBlockProps = {
  selectedVideos: VideoCollection[];
  actions?: TAction[];
};

export type TYouTubePlayer = {
  videoId: string;
  pause: () => void;
} | null;

export const VideosBlock: React.FC<TVideosBlockProps> = ({
  selectedVideos,
  actions,
}) => {
  const iframeRefs = useRef<TYouTubePlayer[]>([]);

  const pauseAllVideos = (videoId: string): void => {
    iframeRefs.current.forEach(iframe => {
      if (videoId !== iframe?.videoId) {
        iframe?.pause();
      }
    });
  };

  return (
    <div className='-mt-8 overflow-hidden xl:-mt-12'>
      <Container variant={EContainerVariant.Default}>
        {!!selectedVideos?.length && (
          <div className='overflow-visible'>
            <Swiper
              className='relative -mb-8 flex overflow-visible xl:-mb-12'
              slidesPerView={1.045}
              spaceBetween={8}
              breakpoints={{
                [parseInt(baseTailwindConfig.screens.md)]: {
                  slidesPerView: 2,
                },
                [parseInt(baseTailwindConfig.screens.lg)]: {
                  slidesPerView: 3,
                },
                [parseInt(baseTailwindConfig.screens.xl)]: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
              }}
              wrapperClass={'flex'}
              loop={false}
              pagination={false}
              simulateTouch={true}
              followFinger={false}
              scrollbar={false}
            >
              {selectedVideos?.map((currentVideo, index) => (
                <SwiperSlide
                  className='cursor-grab py-8 xl:py-12'
                  key={currentVideo.id}
                >
                  <div className='max-w-[calc(100vw-2rem)] flex-1 shrink-0 basis-full md:max-w-[25.438rem]'>
                    <Video
                      video={currentVideo}
                      size={EVideoSize.M}
                      pauseAllVideos={pauseAllVideos}
                      ref={ref => {
                        iframeRefs.current[index] = ref;
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {!!actions?.length && (
          <div className='mt-8 w-full text-center 2xl:mt-12'>
            {actions?.map(
              link =>
                link.type === 'link' && (
                  <Link
                    key={String(link.props.children)}
                    {...link.props}
                    className='mb-2 flex justify-center rounded-xl bg-surface-100 p-5 text-base font-medium leading-relaxed text-black transition first:mb-0 hover:bg-control-150 aria-disabled:cursor-not-allowed aria-disabled:opacity-40'
                  />
                ),
            )}
          </div>
        )}
      </Container>
    </div>
  );
};
