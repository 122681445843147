export enum ECardWithLogoContentAlign {
  Center = 'center',
  Left = 'left',
}

export enum ECardWithLogoTheme {
  CardWithLogo1 = 'card_with_logo_1',
  CardWithLogo2 = 'card_with_logo_2',
}

export const enum ECardWithLogoRowHorizontalScroll {
  No = 'no',
  Yes = 'yes',
  YesXlXxl = 'yes_2xl_xl',
  YesLgMdSm = 'yes_lg_md_sm',
}
