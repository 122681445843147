import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import { EBannerCardsShowAs } from './BannerCards.constants';
import { ETabSize, ETabTheme } from '../../atoms/Tab';
import { Tabs } from '../../atoms/Tabs';
import { Container } from '../../sections/Container';
import BannerCard from '../BannerCard/BannerCard';

export interface IBannerCardsProps {
  banners: (React.ComponentProps<typeof BannerCard> & { tabTitle?: string })[];
  showAs: EBannerCardsShowAs | string;
  showNumbersBeforeTabName?: boolean;
  tabStyle?: {
    tabSize?: ETabSize | string;
    tabVariant?: ETabTheme | string;
  };
}

const BannerCards: React.FC<IBannerCardsProps> = props => {
  const {
    banners,
    showAs,
    showNumbersBeforeTabName = true,
    tabStyle = {},
  } = props;

  const preparedTabStyle = Object.assign(
    {
      tabSize: ETabSize.Medium,
      tabVariant: ETabTheme.Text,
    },
    tabStyle,
  );

  const buttonsWrapRef = useRef<HTMLDivElement>(null);

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  return (
    <section
      className={clsx(
        'grid',
        showAs === EBannerCardsShowAs.Stack && 'gap-4 2xl:gap-8',
        showAs === EBannerCardsShowAs.Tabs && 'gap-6',
      )}
    >
      {showAs === EBannerCardsShowAs.Tabs && (
        <div
          ref={buttonsWrapRef}
          className={clsx(
            'scrollbar-hidden flex overflow-auto scroll-smooth',
            preparedTabStyle.tabVariant !== ETabTheme.Text &&
              'px-4 lg:container',
          )}
        >
          <div className='mx-auto'>
            <Tabs
              options={banners.map((card, index) => ({
                label: card.tabTitle ?? card.title ?? '',
                value: String(index),
                prefix: showNumbersBeforeTabName ? (
                  <span className='flex aspect-square h-5 w-5 items-center justify-center rounded-[6px] bg-current text-xs leading-[1]'>
                    <span className='text-white'>{index + 1}</span>
                  </span>
                ) : null,
                className: 'whitespace-nowrap',
              }))}
              onChange={value => {
                setCurrentBannerIndex(+value);
              }}
              size={preparedTabStyle.tabSize as ETabSize}
              value={String(currentBannerIndex)}
              theme={preparedTabStyle.tabVariant as ETabTheme}
              className='lg:flex-wrap lg:justify-center'
            />
          </div>
        </div>
      )}
      {showAs === EBannerCardsShowAs.Tabs && (
        <Container>
          <BannerCard {...banners[currentBannerIndex]} />
        </Container>
      )}
      {showAs === EBannerCardsShowAs.Stack && (
        <Container>
          {banners.map((banner, i) => (
            <div key={i} className='mb-4 last:mb-0'>
              <BannerCard {...banner} />
            </div>
          ))}
        </Container>
      )}
      {showAs === EBannerCardsShowAs.Scroll && (
        <div className='scrollbar-hidden overflow-auto scroll-smooth'>
          <Container>
            <div className='flex gap-4'>
              {banners.map((banner, i) => (
                <div key={i} className='min-w-[95%] lg:min-w-full'>
                  <BannerCard {...banner} />
                </div>
              ))}
            </div>
          </Container>
        </div>
      )}
    </section>
  );
};

export default BannerCards;
