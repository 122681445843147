import { Media } from '@shared/master-types';
import { ImageProps } from 'next/image';
import React from 'react';

import { EQuoteCardBackground } from './QuoteCard.constants';
import { ReactComponent as IconAvatarDefault } from '../../../../public/icons/avatar.svg';
import { ReactComponent as IconQuotationMark } from '../../../../public/icons/components/quote/quotationMark.svg';
import toImageProps from '../../../utils/toImageProps';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { RichText, TRichTextContent } from '../../atoms/RichText';
import { Container } from '../../sections/Container';

export type TQuoteCardProps = {
  authorTitle?: string;
  authorPosition?: string;
  banner?: ImageProps | null;
  description?: TRichTextContent;
  theme?: EQuoteCardBackground;
  authorAvatar?: string | Media;
};

const QuoteCardWithAvatar: React.FC<TQuoteCardProps> = props => {
  const { authorTitle, authorPosition, description, authorAvatar } = props;
  const avatarProps = toImageProps(authorAvatar);

  return (
    <Container>
      <div className='flex flex-col gap-4 lg:flex-row'>
        <div className='mx-auto flex max-w-[1024px] flex-col gap-5 text-interface-1000 2xl:self-stretch'>
          <div className='mx-auto flex h-[3.579rem] w-[3.25rem] items-center justify-center'>
            <IconQuotationMark />
          </div>
          {description && (
            <div className='text-center text-lg font-normal leading-relaxed lg:text-7xl lg:leading-snug'>
              <RichText content={description} />
            </div>
          )}
          <div className='mx-auto flex flex-row items-center gap-4 lg:gap-5'>
            {avatarProps ? (
              <ImageWithPlaceholder
                {...avatarProps}
                itemProp='image'
                width={80}
                height={80}
                className='h-[5rem] w-[5rem] overflow-hidden rounded-full object-cover object-center'
              />
            ) : (
              <IconAvatarDefault />
            )}
            <div className='flex flex-col'>
              {authorTitle && (
                <span className='text-lg font-bold leading-extra-loose'>
                  {authorTitle}
                </span>
              )}
              {authorPosition && (
                <span className='text-base font-medium leading-relaxed text-interface-800 sm:max-md:w-min'>
                  {authorPosition}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default QuoteCardWithAvatar;
