import { BlogEvent, Jurisdiction, Locale } from '@shared/master-types';
import clsx from 'clsx';
import { trim } from 'lodash';
import Link from 'next/link';
import React, { ReactNode } from 'react';

import {
  Container,
  TAction,
  toImageProps,
  EventCard,
  EPageTypes,
  EEventCardTheme,
  EEventCardRowScroll,
} from '../../../../src/index';
import buildTactionLinkWithJurisdictionSupport from '../../../utils/buildTactionLinkWithJurisdictionSupport';

export interface IBlogEventExtended extends BlogEvent {
  currentLocale: string;
  jurisdictionList?: Jurisdiction[];
  locales?: Locale[];
}

export type TEventCardRowProps = {
  eventsList: IBlogEventExtended[];
  blockTitle?: string;
  pagination: ReactNode;
  eventsArchiveLinks?: TAction[];
  emptyText?: string;
  isUpcoming?: boolean;
  eventMenu?:
    | {
        links?: TAction[];
      }[]
    | undefined;
  currentPath: string;
  isLoading: boolean;
  scroll?: `${EEventCardRowScroll}`;
  eventCardTheme?: `${EEventCardTheme}`;
};

const EventCardRow: React.FC<TEventCardRowProps> = ({
  eventsList,
  eventMenu = [],
  pagination,
  emptyText,
  eventsArchiveLinks,
  currentPath,
  isLoading,
  eventCardTheme = EEventCardTheme.EventCard1,
}) => {
  const linkClasses =
    'flex h-full snap-center items-center rounded-xl px-6 py-3 text-base font-medium leading-relaxed transition lg:hover:bg-brand-500 lg:hover:text-white';

  return (
    <Container>
      {eventMenu.length !== 0 && (
        <div className='scrollbar-hidden mx-auto mb-8 flex snap-x snap-mandatory justify-start gap-3 overflow-x-auto lg:ml-auto lg:mr-auto lg:min-w-full lg:justify-center  2xl:mb-12'>
          {eventMenu?.map(menu =>
            menu?.links?.map((link, index) => {
              const isActive =
                link.type === 'link' &&
                trim(currentPath, '/') === trim(String(link.props.href), '/');

              return (
                link.type === 'link' && (
                  <Link
                    key={index}
                    className={clsx(linkClasses, {
                      'bg-brand-500 text-white': isActive,
                      'bg-control-100 text-control-950': !isActive,
                    })}
                    {...link.props}
                  >
                    {link.props.children}
                  </Link>
                )
              );
            }),
          )}
        </div>
      )}
      {eventsList && eventsList.length > 0 ? (
        <section className='flex flex-col gap-10 2xl:mt-4 2xl:gap-4'>
          {eventsList.map(currentEvent => {
            const {
              id,
              cover,
              title,
              url: defaultUrl,
              excerpt,
              currentLocale,
              jurisdictionList,
              locales,
              slug,
              location,
              startDate,
              endDate,
            } = currentEvent;

            const preparedUrl = buildTactionLinkWithJurisdictionSupport(
              title,
              EPageTypes.BlogEvents,
              currentLocale,
              defaultUrl,
              slug,
              locales,
              jurisdictionList,
            );

            return (
              <EventCard
                key={id}
                image={toImageProps(cover)}
                theme={eventCardTheme}
                title={title}
                url={preparedUrl}
                tagLocation={location}
                startDate={startDate}
                endDate={endDate}
                description={excerpt}
                locale={currentLocale}
              />
            );
          })}
        </section>
      ) : (
        !isLoading &&
        emptyText && (
          <p className='mx-auto w-full max-w-[39.5rem] rounded-xl bg-surface-100 p-8 text-center text-lg font-normal text-interface-1000'>
            {emptyText}
          </p>
        )
      )}
      {pagination}
      {eventsArchiveLinks &&
        eventsArchiveLinks?.map(
          link =>
            link.type === 'link' && (
              <Link
                key={String(link.props.children)}
                {...link.props}
                className='mb-2 mt-8 flex w-full justify-center rounded-xl bg-surface-100 p-5 text-center text-sm font-medium leading-normal text-black transition hover:bg-control-150 aria-disabled:cursor-not-allowed aria-disabled:opacity-40 2xl:mt-12'
              />
            ),
        )}
    </Container>
  );
};

export default EventCardRow;
