import React from 'react';

import { Card } from '../Card';
import { ETitleSize } from '../Card/Card.constants';

export type TFeatureTileProps = Required<
  Pick<
    React.ComponentPropsWithoutRef<typeof Card>,
    'description' | 'icon' | 'title'
  >
>;

/**
 * A basic implementation of the Card component.
 *
 * @param props - FeatureTile props.
 * @class
 */
const FeatureTile: React.FC<TFeatureTileProps> = props => {
  return (
    <div className='h-full rounded-2xl bg-surface-100 p-8'>
      <Card {...props} titleSize={ETitleSize.Medium} />
    </div>
  );
};

export default FeatureTile;
