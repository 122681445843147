import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { TAccordionProps } from './Accordion';
import { ReactComponent as IconClose } from '../../../../public/icons/components/accordionItemV3/icon-close.svg';
import { ReactComponent as IconPlus } from '../../../../public/icons/components/accordionItemV3/icon-plus.svg';
import Button from '../Button/Button';

const AccordionItemV3: React.FC<TAccordionProps> = props => {
  const {
    description,
    title,
    _description,
    isOpened,
    className,
    showCloseIcon = true,
    isActiveClosable = true,
    buttons,
    onClick,
  } = props;

  const [animationParent] = useAutoAnimate();
  const [isCollapsed, setIsCollapsed] = useState(!isOpened);

  const sectionClass =
    'cursor-pointer p-6 md:p-8 transition cursor-pointer border-t-2 border-surface-100 transition xl:w-[51rem] mx-auto';
  const headerTextClass =
    'text-2xl flex justify-between font-semibold leading-extra-loose text-interface-1000 hover:opacity-60';

  useEffect(() => {
    setIsCollapsed(!isOpened);
  }, [isOpened]);

  const onClickSection = (): void => {
    if (onClick) {
      onClick();
    } else {
      setIsCollapsed(c => !c);
    }
  };

  return (
    <div
      ref={animationParent}
      className={clsx(sectionClass, className, {
        'pointer-events-none': !isCollapsed && !isActiveClosable,
      })}
      onClick={onClickSection}
    >
      <div className='flex items-start justify-between gap-4'>
        <div>
          <h3 className={headerTextClass}>{title}</h3>

          {!isCollapsed && !_description && description && (
            <p className='mt-4 text-base leading-extra-loose text-interface-800'>
              {description}
            </p>
          )}
          {!isCollapsed && _description && (
            <div className={'mt-4 flex flex-col gap-7 pe-2'}>
              <div className='text-base text-interface-800 prose-p:pe-2 prose-p:leading-mega-loose prose-p:empty:hidden prose-ol:grid prose-ol:list-decimal prose-ol:gap-2 prose-ol:pl-6 prose-ol:ps-6 prose-ul:grid prose-ul:gap-2 prose-ul:ps-6 prose-li:leading-[1.444rem] prose-li:before:top-[2.5px] [&_div]:flex [&_div]:flex-col [&_div]:gap-4'>
                {_description}
              </div>
              {buttons && buttons.length !== 0 && (
                <div className='flex flex-row flex-wrap items-start gap-2.5'>
                  {buttons.map((button, index) => (
                    <Button key={index} {...button}>
                      {button.children}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {showCloseIcon && (
          <div className='hidden hover:opacity-60 md:block'>
            {isCollapsed ? (
              <IconPlus
                width={16}
                height={16}
                className='aspect-square size-4'
              />
            ) : (
              isActiveClosable && (
                <IconClose
                  width={16}
                  height={16}
                  className='aspect-square size-4'
                />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionItemV3;
