import React from 'react';

import { CardWithQuote, TCardWithQuoteProps } from '../../atoms/CardWithQuote';
import { Container } from '../../sections/Container';

export type TCardWithQuoteRowProps = {
  cards: TCardWithQuoteProps[];
};

export const CardWithQuoteRow: React.FC<TCardWithQuoteRowProps> = props => {
  const { cards = [] } = props;
  return (
    <section>
      <Container>
        <div className='flex flex-col gap-2'>
          {cards &&
            cards.map((item, index) => <CardWithQuote key={index} {...item} />)}
        </div>
      </Container>
    </section>
  );
};
