import clsx from 'clsx';
import React from 'react';

import { TableBlock, TTableBlock } from './components';
import { ECardWithTableTheme, THEMES } from './constants';
import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../../atoms/Eyebrow';
import { ETitlePartsSize, TitlePart } from '../../atoms/TitlePart';

type TCardWithTableProps = {
  eyebrow?: string;
  title?: string;
  theme: ECardWithTableTheme;
  blocks: TTableBlock[];
};

export const CardWithTable: React.FC<TCardWithTableProps> = props => {
  const {
    eyebrow,
    title,
    blocks,
    theme = ECardWithTableTheme.CardWithTable1,
  } = props;

  return (
    <div
      className={clsx(
        'flex flex-col gap-y-6 rounded-4xl lg:gap-y-8 xl:gap-y-20',
        THEMES[theme].cardBorder ? 'p-[1.875rem]' : 'p-8',
        THEMES[theme].cardBorder,
        THEMES[theme].cardBackground,
      )}
    >
      {(title || eyebrow) && (
        <div className='flex flex-col gap-2'>
          {eyebrow && (
            <div>
              <Eyebrow
                size={EEyebrowSize.Medium}
                variant={EEyebrowVariant.TextTracking}
                className={THEMES[theme].eyebrowColor}
              >
                {eyebrow}
              </Eyebrow>
            </div>
          )}
          {title && <TitlePart size={ETitlePartsSize.Xl}>{title}</TitlePart>}
        </div>
      )}

      {blocks?.length ? (
        <div className='2xl:mr-2'>
          {blocks.map((block, blockIndex) => (
            <TableBlock
              key={blockIndex}
              block={block}
              hasDivider={blockIndex > 0}
              dividerClassName={THEMES[theme].dividerColor}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
