import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React, { useMemo } from 'react';

import { EBannerHide } from './BannerList.constants';
import { Container, EContainerVariant } from '../../sections/Container';

export type TBannerProps = {
  /**
   * Image.
   */
  banner: ImageProps | null;

  /**
   * Choose when the image will be hidden.
   */
  hide: EBannerHide;
};

export type TBannerListProps = {
  /**
   * Array of images.
   */
  bannerList: TBannerProps[];

  /**
   * Choose container size.
   */
  containerVariant: EContainerVariant;
};

export const BannerList: React.FC<TBannerListProps> = props => {
  const { bannerList, containerVariant = EContainerVariant.Default } = props;

  const Child = useMemo(
    () =>
      bannerList?.map(
        (element, index) =>
          element?.banner && (
            <div
              key={index}
              className={clsx({
                flex: element.hide === EBannerHide.Never,
                'hidden 2xl:flex': element.hide === EBannerHide.Tablet,
                'hidden lg:flex': element.hide === EBannerHide.Mobile,
              })}
            >
              <Image
                {...element.banner}
                sizes={
                  element.hide === EBannerHide.Never
                    ? '(max-width: 768px) 90vw, (max-width: 1024px) 50vw, 33vw'
                    : '33vw'
                }
              />
            </div>
          ),
      ),
    [bannerList],
  );

  return (
    <Container variant={containerVariant}>
      <div className='-mx-4 flex overflow-hidden lg:-mx-0 lg:rounded-[2rem] 2xl:rounded-3xl'>
        {Child}
      </div>
    </Container>
  );
};
