import { useInView } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Story } from '../../../../atoms/Story';
import StoryCTA, { TStoryCtaProps } from '../../../../atoms/StoryCTA/StoryCTA';
import { TStoryCard } from '../../CardWithStories.constants';

export type TSwiperSliderProps = {
  list: TStoryCard[];
  selectedCard?: number;
  onClick?: (index: number) => void;
  button?: TStoryCtaProps;
};

export const SwiperSlider: React.FC<TSwiperSliderProps> = props => {
  const { list = [], onClick, button } = props;
  const [activeCardIndex, setActiveCardIndex] = useState<number>();
  const [autoplayEnabled, setAutoplayEnabled] = useState(false);
  const swiperSettings = {
    modules: [Autoplay],
    spaceBetween: 0,
    slidesPerView: 'auto' as const,
    className: 'flex overflow-visible mx-0',
    wrapperClass: 'flex ease-linear -mx-4',
    speed: 600,
  };
  const autoPlaySettings = {
    delay: 1,
    stopOnLastSlide: true,
    disableOnInteraction: true,
    pauseOnMouseEnter: true,
  };

  const middleIndex = Math.ceil(list.length / 2);
  const preparedList = list.map(
    ({ highlightedCards: _highlightedCards, ...rest }) => rest,
  );
  const listTop = preparedList.slice(0, middleIndex);
  const listBottom = preparedList.slice(middleIndex);

  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  useEffect(() => {
    if (isInView) {
      setAutoplayEnabled(true);
    } else {
      setAutoplayEnabled(false);
    }
  }, [isInView]);

  return (
    <div ref={ref} className='box-border flex flex-col gap-4 ps-4'>
      {autoplayEnabled && (
        <>
          <Swiper
            {...swiperSettings}
            autoplay={autoplayEnabled ? autoPlaySettings : false}
          >
            {listTop?.length
              ? listTop.map((story, index) => (
                  <SwiperSlide
                    className='me-4 w-auto first:ps-4 last:me-0'
                    key={index}
                  >
                    <Story
                      {...story}
                      selected={index === activeCardIndex}
                      onClick={() => {
                        setActiveCardIndex(index);
                        if (onClick) {
                          onClick(index);
                        }
                      }}
                    />
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
          <Swiper
            {...swiperSettings}
            autoplay={
              autoplayEnabled
                ? { ...autoPlaySettings, reverseDirection: true }
                : false
            }
            initialSlide={list?.length - 1}
          >
            {button && (
              <SwiperSlide className='w-auto first:ps-4'>
                <StoryCTA {...button} />
              </SwiperSlide>
            )}
            {listBottom?.length
              ? listBottom.map((story, i) => {
                  const index = i + middleIndex;
                  return (
                    <SwiperSlide className='ms-4 w-auto first:ms-0' key={index}>
                      <Story
                        {...story}
                        selected={index === activeCardIndex}
                        onClick={() => {
                          setActiveCardIndex(index);
                          if (onClick) {
                            onClick(index);
                          }
                        }}
                      />
                    </SwiperSlide>
                  );
                })
              : null}
          </Swiper>
        </>
      )}
    </div>
  );
};
