import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React, { useEffect, useState } from 'react';

import {
  EAccordionV2IconPosition,
  EAccordionV2ImagePositionDesktop,
  EAccordionV2ImagePositionMobile,
  imageCVA,
} from './constants';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import {
  TRichTextContent,
  RichText,
  TRichTextBulletsOptions,
} from '../RichText';
import isRichTextContentEmpty from '../RichText/utils/checkEmptyRichText';

export type TAccordionCardV2 = {
  title?: string;
  cardDescriptionList: {
    description: TRichTextContent;
  }[];
  bottomDescription?: TRichTextContent;
  isOpened?: boolean;
  isActiveClosable?: boolean;
  onClick?: () => void;
  image?: ImageProps | null;
  icon?: ImageProps | null;
  cardIconPosition?: `${EAccordionV2IconPosition}`;
  cardImagePositionMobile?: `${EAccordionV2ImagePositionMobile}`;
  cardImagePositionDesktop?: `${EAccordionV2ImagePositionDesktop}`;
  bulletsOptions?: TRichTextBulletsOptions;
  lightBackground?: string;
  showBorderOnMobile?: boolean;
  isDarkBackground?: boolean;
};

const AccordionCardV2: React.FC<TAccordionCardV2> = props => {
  const {
    title,
    isOpened,
    isActiveClosable = true,
    cardDescriptionList,
    bottomDescription,
    image,
    icon,
    cardIconPosition = EAccordionV2IconPosition.Left,
    cardImagePositionMobile = EAccordionV2ImagePositionMobile.Top,
    onClick,
    bulletsOptions,
    lightBackground,
    showBorderOnMobile = false,
  } = props;

  const [animationParent] = useAutoAnimate();
  const [isCollapsed, setIsCollapsed] = useState(!isOpened);

  const contentDescriptionClasses = clsx(
    'text-sm font-normal leading-mega-loose text-interface-800 prose-p:pe-2 prose-p:pt-2 prose-p:pt-2 prose-ol:pt-4 prose-ul:flex prose-ul:flex-col prose-ul:gap-2 prose-ul:pb-0 prose-ul:pt-4 xl:prose-ol:pt-2 xl:prose-ul:pt-2 prose-li:before:w-4 prose-li:before:h-4 prose-li:before:top-1',
  );

  const bottomDescriptionClasses =
    'pe-2 pt-2 font-normal text-interface-800 xl:pt-[3.5rem] text-sm leading-mega-loose';

  useEffect(() => {
    setIsCollapsed(!isOpened);
  }, [isOpened]);

  const onClickSection = (): void => {
    if (!isCollapsed && !isActiveClosable) {
      return;
    }

    if (onClick) {
      onClick();
    } else {
      setIsCollapsed(c => !c);
    }
  };

  return (
    <div
      ref={animationParent}
      className={clsx(
        'group flex w-full min-w-[calc(100vw_-_2rem)] flex-1 rounded-3xl md:min-w-[18.5rem] lg:min-w-[22.5rem] xl:min-w-full xl:gap-4 xl:rounded-[2rem] xl:border-control-200 xl:px-6 xl:py-[1.41rem] xl:transition-all xl:hover:border-interface-1000',
        cardImagePositionMobile === EAccordionV2ImagePositionMobile.Top
          ? 'flex-col'
          : 'flex-col-reverse',
        !isCollapsed && !isActiveClosable
          ? 'xl:border-interface-1000 '
          : 'xl:cursor-pointer',
        showBorderOnMobile
          ? 'border-2 border-control-250'
          : 'gap-4 md:gap-0 md:border-2 md:border-control-250',
      )}
      onClick={onClickSection}
    >
      {image && (
        <div
          className={imageCVA({
            position:
              cardImagePositionMobile as EAccordionV2ImagePositionMobile,
            showBorderOnMobile,
          })}
          style={{
            backgroundColor: lightBackground,
          }}
        >
          <ImageWithPlaceholder
            {...image}
            itemProp='image'
            width={360}
            height={197}
            className='h-full w-full'
          />
        </div>
      )}
      <div
        className={clsx(
          'flex flex-col items-start justify-between gap-2 md:p-6 xl:p-0',
          {
            'p-6': showBorderOnMobile,
          },
        )}
      >
        <div
          className={clsx(
            'flex w-full items-center gap-2',
            cardIconPosition === EAccordionV2IconPosition.Left
              ? 'flex-row-reverse justify-end'
              : 'flex-row justify-start',
          )}
        >
          <h3 className='font-sm w-full font-semibold leading-extra-loose text-interface-1000'>
            {title}
          </h3>
          {icon && (
            <ImageWithPlaceholder
              {...icon}
              itemProp='image'
              width={24}
              height={24}
              className={clsx(
                'h-[1.5rem] w-[1.5rem] object-contain object-center transition-all group-hover:opacity-100',
                {
                  'opacity-20': isCollapsed || isActiveClosable,
                },
              )}
            />
          )}
        </div>

        {(!!cardDescriptionList?.length || bottomDescription) && (
          <div className={clsx(!isCollapsed ? 'block' : 'xl:hidden')}>
            {!!cardDescriptionList?.length && (
              <div className='flex flex-col gap-2'>
                {cardDescriptionList?.map(
                  (item, index) =>
                    !isRichTextContentEmpty(item.description) && (
                      <RichText
                        key={index}
                        bulletsOptions={bulletsOptions}
                        className={contentDescriptionClasses}
                        content={item.description}
                      />
                    ),
                )}
              </div>
            )}
            {bottomDescription &&
              !isRichTextContentEmpty(bottomDescription) && (
                <RichText
                  className={bottomDescriptionClasses}
                  content={bottomDescription}
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionCardV2;
