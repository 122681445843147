import React from 'react';

import { SocialMediaList, TSocialMediaListProps } from '../SocialMediaList';

export type TSocialsProps = {
  title?: string;
  description?: string;
  socialMedia?: TSocialMediaListProps['companyLinks'];
};

export const Socials: React.FC<TSocialsProps> = ({
  title,
  description,
  socialMedia,
}) => {
  return (
    <div className='w-full'>
      {title && (
        <h3 className='border-b border-light-surface-200 pb-4 text-sm font-medium uppercase leading-relaxed tracking-[0.28rem] text-light-interface-500'>
          {title}
        </h3>
      )}
      {description && (
        <p className='w-full py-6 text-sm leading-[1.488rem] text-light-interface-800 xl:max-w-[26rem]'>
          {description}
        </p>
      )}
      {socialMedia && <SocialMediaList companyLinks={socialMedia} />}
    </div>
  );
};
