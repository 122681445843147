import { ImageProps } from 'next/image';
import React from 'react';

import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { Container } from '../../sections/Container';

export interface ICurrencyListProps {
  currencyList?: {
    label: string;
    media: ImageProps | null;
  }[];
}

const CurrencyList: React.FC<ICurrencyListProps> = props => {
  const { currencyList } = props;

  return (
    <Container>
      <div className='flex flex-wrap items-center justify-center gap-8 2xl:gap-18'>
        {currencyList?.map(currencyItem => (
          <div
            key={currencyItem.label}
            className='flex items-center gap-2 2xl:gap-4 2xl:py-1'
          >
            {currencyItem && currencyItem.media && (
              <ImageWithPlaceholder
                {...currencyItem.media}
                key={currencyItem.label}
                width={40}
                height={40}
                className='w-full max-w-[1.5rem] shrink-0 object-contain object-center 2xl:max-w-[2.5rem]'
              />
            )}
            <p className='text-sm font-medium text-interface-800 2xl:text-2xl'>
              {currencyItem.label}
            </p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default CurrencyList;
