import clsx from 'clsx';
import { cva } from 'cva';
import React from 'react';

import { Eyebrows, SideImage } from './components';
import {
  EBrandCardBgV2AlignContent,
  EBrandCardBgV2Theme,
  EBrandCardBgV2ImageSize,
  EBrandCardBgV2ShowMobileImage,
  THEMES,
} from './constants';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import {
  ETitlePartsPosition,
  ETitlePartsSize,
  TitlePart,
} from '../../atoms/TitlePart';

import type { ImageProps } from 'next/image';

const cardContentCVA = cva('flex flex-col justify-center gap-2 px-6 py-8', {
  variants: {
    alignContent: {
      [EBrandCardBgV2AlignContent.Center]: 'items-center',
      [EBrandCardBgV2AlignContent.Left]: '',
    },
    imageSize: {
      [EBrandCardBgV2ImageSize.L]: '',
      [EBrandCardBgV2ImageSize.S]: '',
    },
    hasImages: {
      true: 'shrink-0 md:w-[384px] lg:w-[288px]',
      false: '',
    },
  },
  compoundVariants: [
    {
      hasImages: true,
      imageSize: EBrandCardBgV2ImageSize.L,
      className: 'xl:w-[432px] 2xl:w-[472px]',
    },
    {
      hasImages: true,
      imageSize: EBrandCardBgV2ImageSize.S,
      className: 'xl:w-[544px] 2xl:w-[784px]',
    },
  ],
});

export type TBrandCardBgV2CardProps = {
  centerImage?: ImageProps | null;
  leftImage?: ImageProps | null;
  rightImage?: ImageProps | null;
  eyebrows?: string[];
  title?: string;
  alignContent?: EBrandCardBgV2AlignContent;
  theme?: EBrandCardBgV2Theme;
  imageSize?: EBrandCardBgV2ImageSize;
  showMobileImage?: EBrandCardBgV2ShowMobileImage;
  className?: string;
};

export const BrandCardBgV2: React.FC<TBrandCardBgV2CardProps> = props => {
  const {
    eyebrows,
    centerImage,
    title,
    leftImage,
    rightImage,
    alignContent = EBrandCardBgV2AlignContent.Center,
    imageSize = EBrandCardBgV2ImageSize.L,
    theme = EBrandCardBgV2Theme.BrandCardBgV21,
    showMobileImage = EBrandCardBgV2ShowMobileImage.OnlyLeft,
  } = props;

  const hasImages = !!leftImage || !!rightImage;

  return (
    <div
      className={clsx(
        'flex flex-col overflow-hidden rounded-4xl md:min-h-[191px] md:flex-row',
        THEMES[theme]?.cardBackground,
        THEMES[theme]?.borderStyle,
      )}
    >
      <SideImage
        image={leftImage}
        className={
          showMobileImage === EBrandCardBgV2ShowMobileImage.OnlyRight
            ? 'hidden lg:block'
            : ''
        }
      />

      <div className={cardContentCVA({ alignContent, imageSize, hasImages })}>
        <Eyebrows
          eyebrows={eyebrows}
          className={THEMES[theme]?.eyebrowColor}
          alignContent={alignContent}
        />

        {!!centerImage && (
          <ImageWithPlaceholder {...centerImage} className='h-12' />
        )}

        {!!title && (
          <TitlePart
            size={ETitlePartsSize.L}
            className='xl:text-5xl xl:font-semibold xl:leading-loose'
            position={
              alignContent === EBrandCardBgV2AlignContent.Center
                ? ETitlePartsPosition.Center
                : ETitlePartsPosition.Left
            }
          >
            {title}
          </TitlePart>
        )}
      </div>

      <SideImage
        image={rightImage}
        className={
          showMobileImage === EBrandCardBgV2ShowMobileImage.OnlyLeft
            ? 'hidden lg:block'
            : ''
        }
      />
    </div>
  );
};
