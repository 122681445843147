import React from 'react';

import {
  Container,
  EDefaultCardLayout,
  EIconSize,
  ETagSize,
  ETagTheme,
  ETagVariant,
} from '../../../../src/index';
import { toImageProps } from '../../../utils';
import { TCompanyRankingExtended } from '../../../utils/buildCompanyRankingsList';
import { Accordion } from '../../atoms/Accordion';
import { RichText } from '../../atoms/RichText';
import { DefaultCard } from '../DefaultCard';

export type TCompanyRankingsListProps = {
  tagId?: string;
  docsList: TCompanyRankingExtended[];
  pagination: React.ReactNode;
  emptyText?: string;
  isLoading: boolean;
};
const CompanyRankingsUncategorizedList: React.FC<TCompanyRankingsListProps> = ({
  docsList,
  pagination,
  emptyText,
  isLoading,
}) => {
  return !isLoading ? (
    <Container>
      {docsList?.length ? (
        <>
          <div className='-mx-4 grid grid-cols-1 gap-12'>
            {docsList?.map(currentArticle => {
              const { id, title, logo, excerpt, location, accordion } =
                currentArticle;

              const badgesArray = location
                ? [
                    {
                      variant: ETagVariant.Contained,
                      theme: ETagTheme.Secondary,
                      size: ETagSize.Small,
                      children: location,
                    },
                  ]
                : [];

              return (
                <div key={id} className='flex w-full flex-col gap-4'>
                  <DefaultCard
                    icon={toImageProps(logo)}
                    iconSize={EIconSize.XxxL}
                    size={EDefaultCardLayout.DefaultCardWithBg}
                    key={id}
                    title={title}
                    badges={badgesArray}
                    description={excerpt}
                  />
                  {!!accordion &&
                    accordion?.map(item => (
                      <Accordion
                        key={item.title}
                        _description={<RichText content={item.description} />}
                        title={item.title}
                      />
                    ))}
                </div>
              );
            })}
          </div>
          {pagination}
        </>
      ) : (
        emptyText && (
          <p className='mx-auto w-full max-w-[39.5rem] rounded-xl bg-surface-100 p-8 text-center text-lg font-normal text-interface-1000'>
            {emptyText}
          </p>
        )
      )}
    </Container>
  ) : null;
};

export default CompanyRankingsUncategorizedList;
