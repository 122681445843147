import clsx from 'clsx';
import React from 'react';

import { Container } from '../../sections/Container';
import { CardWithTable } from '../CardWithTable';

type TCardsWithTableProps = {
  banners: React.ComponentProps<typeof CardWithTable>[];
};

export const CardsWithTable: React.FC<TCardsWithTableProps> = props => {
  const { banners } = props;

  return (
    <section>
      <Container>
        <div
          className={clsx(
            'flex flex-col gap-3',
            banners?.length > 1
              ? 'md:grid md:grid-cols-2 md:gap-4'
              : 'md:items-center md:*:w-[calc(50%-8px)]',
          )}
        >
          {banners.map((banner, i) => (
            <CardWithTable key={i} {...banner} />
          ))}
        </div>
      </Container>
    </section>
  );
};
