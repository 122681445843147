import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import { ECardTableMixedTheme } from './CardTable.constants';
import { ETitleSize } from '../Card/Card.constants';
import {
  EEyebrowSize,
  EEyebrowTheme,
  EEyebrowVariant,
  Eyebrow,
} from '../Eyebrow';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';

export type TCardTableProps = {
  title?: string;
  titleSize?: ETitleSize;
  theme?: ECardTableMixedTheme;
  description?: React.JSX.Element | string | null;
  tableColumns?: {
    rows?: {
      icon?: ImageProps | null;
      eyebrow?: string;
      title?: string;
      description?: React.JSX.Element | string | null;
      valueTitle?: string;
      valueDescription?: React.JSX.Element | string | null;
    }[];
  }[];
};

export const CardTable: React.FC<TCardTableProps> = ({
  title,
  titleSize = ETitleSize.Medium,
  theme,
  description,
  tableColumns,
}) => {
  let cardBgClass = 'bg-surface-100';
  let cardTitleClass = 'text-interface-1000';
  let titleClass = 'text-interface-1000';
  let descriptionClass = 'text-interface-800';
  let valueDescriptionClass = 'text-brand-500';
  let eyebrowTheme = EEyebrowTheme.Primary;

  if (theme === ECardTableMixedTheme.Theme1) {
    cardBgClass = 'bg-surface-100';
    titleClass = 'text-interface-1000';
  } else if (theme === ECardTableMixedTheme.Theme3) {
    cardBgClass = 'bg-brand-500';
    cardTitleClass = 'text-interface-50';
    titleClass = 'text-interface-50';
    descriptionClass = 'text-interface-50/80';
    valueDescriptionClass = 'text-brand-50';
    eyebrowTheme = EEyebrowTheme.Light;
  } else if (theme === ECardTableMixedTheme.Theme5) {
    cardBgClass = 'bg-surface-800';
    cardTitleClass = 'text-interface-50';
    titleClass = 'text-interface-50';
    descriptionClass = 'text-interface-50/80';
  }

  return (
    <div
      className={clsx(
        cardBgClass,
        'flex w-full flex-col rounded-3xl p-8 last:h-full',
      )}
    >
      <div className='flex flex-col'>
        {title && (
          <h3
            className={clsx(
              cardTitleClass,
              'font-semibold leading-relaxed',
              titleSize === ETitleSize.Small && 'text-2xl',
              titleSize === ETitleSize.Medium && 'text-2xl 2xl:text-4xl',
              titleSize === ETitleSize.UnchangingMedium && 'text-4xl',
              titleSize === ETitleSize.Large &&
                'text-4xl 2xl:text-7xl 2xl:leading-snug',
            )}
          >
            {title}
          </h3>
        )}
        {description && (
          <div
            className={clsx(
              descriptionClass,
              'mt-3 text-base leading-extra-loose transition empty:hidden prose-a:text-brand-500 prose-a:hover:text-brand-550',
              'prose-p:pb-3 last:prose-p:pb-0',
            )}
          >
            {description}
          </div>
        )}
      </div>

      {!!tableColumns?.length && (
        <div className='mt-4 flex flex-col gap-4 xl:flex-row xl:gap-20'>
          {tableColumns?.map((tableColumn, i) => (
            <div key={i} className='flex w-full flex-col gap-4'>
              {tableColumn.rows?.map((row, k) => (
                <div key={k} className='flex flex-col items-start gap-2'>
                  {row.eyebrow && (
                    <Eyebrow
                      size={EEyebrowSize.ExtraSmall}
                      variant={EEyebrowVariant.Contained}
                      theme={eyebrowTheme}
                    >
                      {row.eyebrow}
                    </Eyebrow>
                  )}

                  <div className='flex w-full flex-row gap-2'>
                    {row.icon && (
                      <ImageWithPlaceholder
                        {...row.icon}
                        width={16}
                        height={16}
                        className='h-4 w-auto'
                      />
                    )}

                    <div className='flex w-full flex-row justify-between gap-2 lg:gap-x-3'>
                      <div className='flex w-full max-w-[46%] flex-col gap-1 lg:max-w-full'>
                        <h4
                          className={clsx(
                            titleClass,
                            'font-medium leading-relaxed lg:max-w-[16.25rem]',
                          )}
                        >
                          {row.title}
                        </h4>
                        {row.description && (
                          <div
                            className={clsx(
                              descriptionClass,
                              'text-sm leading-loose',
                            )}
                          >
                            {row.description}
                          </div>
                        )}
                      </div>

                      <div className='flex flex-col gap-1 lg:min-w-[7.6rem]'>
                        <h4
                          className={clsx(
                            titleClass,
                            'text-right font-semibold leading-normal',
                          )}
                        >
                          {row.valueTitle}
                        </h4>
                        {row.valueDescription && (
                          <div
                            className={clsx(
                              valueDescriptionClass,
                              'text-right text-xs font-medium leading-relaxed empty:hidden',
                            )}
                          >
                            {row.valueDescription}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
