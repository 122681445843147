import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import Button from '../../../../atoms/Button/Button';
import { Icon } from '../../../../atoms/Icon';
import { EIconSize } from '../../../../atoms/Icon/Icon.constants';

type TAction = React.ComponentProps<typeof Button>;

export type TCellWithLinkProps = {
  action?: TAction;
  hasAnimation?: boolean;
};

const invalidButtonProps: (keyof TAction)[] = [
  'variant',
  'size',
  'theme',
  'leadingIcon',
  'rounded',
  'leadingIcon',
  'trailingIcon',
  'trailingIconComponent',
];

const filterButtonProps = (props: TAction): TAction => {
  return Object.keys(props).reduce((filteredProps, key) => {
    if (!invalidButtonProps.includes(key as keyof TAction)) {
      filteredProps[key as keyof TAction] = props[key as keyof TAction];
    }
    return filteredProps;
  }, {} as TAction);
};
const CellWithLink: React.FC<TCellWithLinkProps> = props => {
  const { action, hasAnimation = false } = props;

  if (!action) {
    return null;
  }
  const icon = action?.trailingIcon;
  const storyCtaContent = (
    <>
      {action?.children && (
        <span
          className={
            'text-sm font-medium leading-loose text-interface-1000 lg:text-lg lg:leading-normal'
          }
        >
          {action?.children}
        </span>
      )}
      {icon && (
        <Icon
          className={clsx(
            'hidden opacity-0 transition-opacity group-hover:opacity-100 md:block',
            { 'group-hover:animate-bounce': hasAnimation },
          )}
          size={EIconSize.L}
          icon={icon}
        />
      )}
    </>
  );
  const className =
    'outline-control-200 transition-[outline-color] min-h-16 lg:min-h-[4.5rem] flex items-center group transition-colors justify-between gap-2 px-6 lg:px-8 py-2 outline outline-1 z-10 hover:z-20 hover:outline-control-950';
  const filteredAction = filterButtonProps(action);

  return filteredAction && 'href' in filteredAction ? (
    <Link {...filteredAction} className={className}>
      {storyCtaContent}
    </Link>
  ) : (
    <button {...filteredAction} className={className}>
      {storyCtaContent}
    </button>
  );
};

export default CellWithLink;
