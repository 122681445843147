import React from 'react';

import buildNumericDateFormat from '../../../utils/buildNumericDateFormat';
import { TTag } from '../NewsCard/NewsCard';
import { ETagSize, ETagTheme, ETagVariant, Tag } from '../Tag';

export type THorizontalNewsCardBottomPart = {
  date: string;
  tags?: TTag[];
};

const HorizontalNewsCardBottomPart: React.FC<
  THorizontalNewsCardBottomPart
> = props => {
  const { date, tags } = props;

  if (!tags?.length && !date) {
    return null;
  }

  return (
    <div className='mt-1 flex items-center gap-2'>
      {tags?.map(({ id, children, ...tag }) => {
        return (
          <Tag
            key={id}
            {...tag}
            variant={ETagVariant.Contained}
            theme={ETagTheme.Secondary}
            size={ETagSize.Small}
            itemProp='about'
            itemScope
            itemType='http://schema.org/Thing'
          >
            <span itemProp='name'>{children}</span>
          </Tag>
        );
      })}
      {date && (
        <span className='text-xs leading-relaxed text-interface-800'>
          {buildNumericDateFormat(date)}
        </span>
      )}
    </div>
  );
};

export default HorizontalNewsCardBottomPart;
