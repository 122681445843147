import React from 'react';

export type TSubHeadingProps = {
  title?: string;
};

const SubHeading: React.FC<TSubHeadingProps> = props => {
  const { title } = props;

  return title ? (
    <h3 className='mx-auto mt-2 block w-full max-w-[51rem] px-4 text-center text-3xl font-semibold leading-relaxed text-interface-1000 xl:text-5xl xl:leading-extra-loose 2xl:mb-0 2xl:mt-4'>
      {title}
    </h3>
  ) : null;
};

export default SubHeading;
