import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import { ENewsCardSize } from '../NewsCard';

export type THorizontalNewsCardContentPart = {
  title: string;
  description?: string;
  cardSize?: ENewsCardSize;
  link: LinkProps;
};

const HorizontalNewsCardContentPart: React.FC<
  THorizontalNewsCardContentPart
> = props => {
  const { title, description, cardSize = ENewsCardSize.M, link } = props;

  if (!link.href) {
    return null;
  }

  return (
    <Link className='block' {...link}>
      <h3
        className={clsx(
          'line-clamp-2 font-semibold text-interface-1000',
          cardSize === ENewsCardSize.M && 'text-2xl leading-extra-loose',
          cardSize === ENewsCardSize.Xl &&
            'text-2xl leading-extra-loose md:text-4xl md:leading-relaxed xl:text-5xl xl:leading-loose',
        )}
      >
        {title}
      </h3>
      {description && (
        <p
          className={clsx(
            'pe-2 text-interface-800',
            cardSize === ENewsCardSize.M &&
              'line-clamp-6 text-base leading-extra-loose',
            cardSize === ENewsCardSize.Xl &&
              'mt-2 line-clamp-3 text-sm leading-mega-loose md:mt-4 md:text-base md:leading-mega-loose',
          )}
        >
          {description}
        </p>
      )}
    </Link>
  );
};

export default HorizontalNewsCardContentPart;
