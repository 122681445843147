import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import { TChartScrollableProps } from './ChartScrollable.types';

export const ChartScrollable: React.FC<TChartScrollableProps> = props => {
  return (
    <div
      className={clsx(
        'p-4',
        'flex flex-shrink-0 flex-grow-0 justify-center',
        'flex-col md:flex-row md:items-center',
      )}
    >
      <div className={clsx('scrollbar-hidden overflow-auto scroll-smooth')}>
        {props.chart.media && (
          <Image {...props.chart.media} style={{ maxWidth: 'initial' }} />
        )}
      </div>

      {props.legend && (
        <div className={clsx('pt-4 md:pl-10 md:pt-0')}>
          {props.legend.media && <Image {...props.legend.media} />}
        </div>
      )}
    </div>
  );
};
