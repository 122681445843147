import React from 'react';

import { Container } from '../../sections/Container';
import { BrandCardBgV2 } from '../BrandCardBgV2';
type TBrandCardsBgV2Props = {
  banners: React.ComponentProps<typeof BrandCardBgV2>[];
};

export const BrandCardsBgV2: React.FC<TBrandCardsBgV2Props> = props => {
  const { banners } = props;

  return (
    <section>
      <Container>
        <div className='flex flex-col gap-4'>
          {banners.map((banner, i) => (
            <BrandCardBgV2 key={i} {...banner} />
          ))}
        </div>
      </Container>
    </section>
  );
};
