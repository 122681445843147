export enum EBrandCardBgV2AlignContent {
  Left = 'Left',
  Center = 'Center',
}

export enum EBrandCardBgV2ImageSize {
  L = 'L',
  S = 'S',
}

export enum EBrandCardBgV2Theme {
  BrandCardBgV21 = 'BrandCardBgV21',
  BrandCardBgV22 = 'BrandCardBgV22',
  BrandCardBgV23 = 'BrandCardBgV23',
  BrandCardBgV24 = 'BrandCardBgV24',
}

export enum EBrandCardBgV2ShowMobileImage {
  OnlyLeft = 'OnlyLeft',
  OnlyRight = 'OnlyRight',
}

type TBrandCardV2Theme = {
  cardBackground: string;
  eyebrowColor: string;
  borderStyle: string;
};

export const THEMES: Record<EBrandCardBgV2Theme, TBrandCardV2Theme> = {
  [EBrandCardBgV2Theme.BrandCardBgV21]: {
    cardBackground: 'bg-accent-550',
    eyebrowColor: 'text-accent-850',
    borderStyle: '',
  },
  [EBrandCardBgV2Theme.BrandCardBgV22]: {
    cardBackground: 'bg-accent-500',
    eyebrowColor: 'text-accent-800',
    borderStyle: '',
  },
  [EBrandCardBgV2Theme.BrandCardBgV23]: {
    cardBackground: 'bg-transparent',
    eyebrowColor: 'text-interface-500',
    borderStyle: 'border-solid border-control-200 border-2',
  },
  [EBrandCardBgV2Theme.BrandCardBgV24]: {
    cardBackground: 'bg-surface-100',
    eyebrowColor: 'text-interface-500',
    borderStyle: '',
  },
};
