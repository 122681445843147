export enum ECardWithTableTheme {
  CardWithTable1 = 'CardWithTable1',
  CardWithTable2 = 'CardWithTable2',
  CardWithTable3 = 'CardWithTable3',
  CardWithTable4 = 'CardWithTable4',
}

export const THEMES = {
  [ECardWithTableTheme.CardWithTable1]: {
    cardBackground: 'bg-accent-550',
    cardBorder: '',
    eyebrowColor: 'text-accent-850',
    dividerColor: 'bg-accent-850/50',
  },
  [ECardWithTableTheme.CardWithTable2]: {
    cardBackground: 'bg-accent-500',
    cardBorder: '',
    eyebrowColor: 'text-accent-800',
    dividerColor: 'bg-accent-800/50',
  },
  [ECardWithTableTheme.CardWithTable3]: {
    cardBackground: 'bg-transparent',
    cardBorder: 'border-solid border-surface-200 border-2',
    eyebrowColor: 'text-interface-500',
    dividerColor: 'bg-control-200',
  },
  [ECardWithTableTheme.CardWithTable4]: {
    cardBackground: 'bg-surface-100',
    cardBorder: '',
    eyebrowColor: 'text-interface-500',
    dividerColor: 'bg-interface-500',
  },
};
