import clsx from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';

import { ECheckboxSize, ECheckboxVariant } from './Checkbox.constants';

export interface ICheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /**
   * Defines the elementSize of the component.
   * Current options are Medium & Large.
   */
  size?: ECheckboxSize;

  /**
   * Defines the weight of the component on the page.
   * If set to "Primary", the component will appear bigger and bolder.
   * If set to "Secondary", it will have a lower visual emphasis with smaller font sizes.
   */
  variant?: ECheckboxVariant;

  /**
   * Defines if the element is displayed over a colored background.
   */
  hasBackground?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>((props, ref) => {
  const {
    size = ECheckboxSize.Large,
    variant = ECheckboxVariant.Primary,
    hasBackground = false,
    checked,
    disabled,
    ...restInputProps
  } = props;

  return (
    <div
      className={clsx(
        'rounded border-2',
        size === ECheckboxSize.Large && 'h-5 w-5',
        size === ECheckboxSize.Medium && 'h-4 w-4',
        variant === ECheckboxVariant.Primary &&
          !hasBackground &&
          'border-brand-500',
        variant === ECheckboxVariant.Secondary &&
          !hasBackground &&
          'border-control-600',
        variant === ECheckboxVariant.Primary && hasBackground && 'border-black',
        variant === ECheckboxVariant.Secondary &&
          hasBackground &&
          'border-white',
        disabled && 'cursor-not-allowed opacity-40',
      )}
    >
      <label
        className={clsx(
          'flex h-full w-full items-center justify-center transition',
          !checked && !disabled && 'opacity-0 hover:opacity-10',
          variant === ECheckboxVariant.Primary &&
            !hasBackground &&
            'bg-brand-500',
          variant === ECheckboxVariant.Secondary &&
            !hasBackground &&
            'bg-control-600',
          variant === ECheckboxVariant.Primary && hasBackground && 'bg-black',
          variant === ECheckboxVariant.Secondary && hasBackground && 'bg-white',
          disabled && 'cursor-not-allowed opacity-40',
          !disabled && 'cursor-pointer',
        )}
      >
        {checked && (
          <svg
            className={clsx(
              'pointer-events-none',
              size === ECheckboxSize.Large && 'h-3 w-3',
              size === ECheckboxSize.Medium && 'h-2.5 w-2.5',
            )}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 12 13'
          >
            <path
              className={clsx(
                variant === ECheckboxVariant.Secondary &&
                  hasBackground &&
                  'fill-black',
                variant === ECheckboxVariant.Secondary &&
                  !hasBackground &&
                  'fill-white',
                variant === ECheckboxVariant.Primary && 'fill-white',
              )}
              d='M11.65 2.77a1.2 1.2 0 0 1 0 1.69l-6.63 6.63a1.2 1.2 0 0 1-1.69 0L.35 8.1a1.2 1.2 0 0 1 1.69-1.7l2.14 2.15 5.78-5.79a1.2 1.2 0 0 1 1.69 0Z'
            />
          </svg>
        )}
        <input
          ref={ref}
          className='hidden'
          type='checkbox'
          checked={checked}
          disabled={disabled}
          aria-checked={checked}
          aria-disabled={disabled}
          {...restInputProps}
        />
      </label>
    </div>
  );
});

export default Checkbox;
