import clsx from 'clsx';
import React from 'react';

import { ENewsCardSize, NewsCard, TNewsCardProps } from '../../atoms/NewsCard';

type TNewsCardRowWp = {
  newsItems: TNewsCardProps[];
  cardSize?: ENewsCardSize;
};

export const NewsCardRow: React.FC<TNewsCardRowWp> = ({
  newsItems,
  cardSize = ENewsCardSize.M,
}) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 gap-y-12 md:gap-x-4 ',
        cardSize === ENewsCardSize.S && 'md:grid-cols-2  xl:grid-cols-4',
        cardSize === ENewsCardSize.M && 'md:grid-cols-2 xl:grid-cols-3',
        cardSize === ENewsCardSize.L && 'md:grid-cols-2  xl:grid-cols-3',
        cardSize === ENewsCardSize.Xl && 'grid-cols-1',
      )}
    >
      {newsItems.map((newsItem, index) => (
        <NewsCard key={index} cardSize={cardSize} {...newsItem} />
      ))}
    </div>
  );
};
