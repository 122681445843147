import clsx from 'clsx';
import React from 'react';

import { Container } from '../../sections/Container';
import { DefaultCard, TDefaultCardProps } from '../DefaultCard';

type TPlatesDefaultCardProps = {
  cards: TDefaultCardProps[];
};

export const PlatesDefaultCard: React.FC<TPlatesDefaultCardProps> = ({
  cards,
}) => {
  return (
    <section>
      <Container>
        <div
          className={clsx(
            'grid gap-4',
            'md:grid-rows-[repeat(2,_minmax(min-content, max-content))]',
            'md:grid-cols-2',
          )}
        >
          {cards.map((card, index) => (
            <div
              key={card.title}
              className={
                !index ? 'md:col-span-2 xl:col-auto xl:row-span-2' : ''
              }
            >
              <DefaultCard
                wrapperClassName={!index ? 'flex flex-col justify-center' : ''}
                {...card}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};
