import clsx from 'clsx';
import React from 'react';

import { CardWithLogo, TCardWithLogo } from '../../../atoms/CardWithLogo';
import { Container } from '../../../sections/Container';

export type TCardWithLogoRowProps = {
  cards: TCardWithLogo[];
};

export const NoScrollRowComponent: React.FC<TCardWithLogoRowProps> = props => {
  const { cards } = props;

  return (
    <section>
      <Container>
        <div
          className={clsx(
            'grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3',
          )}
        >
          {cards.map((item, index) => (
            <CardWithLogo key={index} {...item} />
          ))}
        </div>
      </Container>
    </section>
  );
};
