import { addJurisdictionSupportItem } from '@front/shared/utils';
import { CompanyRanking, Locale } from '@shared/master-types';
import Link from 'next/link';
import React from 'react';

import {
  Container,
  EActionType,
  EDefaultCardLayout,
  EIconSize,
  ETagSize,
  ETagTheme,
  ETagVariant,
  TAction,
  toLinkProps,
} from '../../../../src/index';
import { toImageProps } from '../../../utils';
import { DefaultCard } from '../DefaultCard';

export type TCompanyRankingExtended = CompanyRanking & {
  currentLocale: string;
  locales?: Locale[];
  slug?: string;
};

export type TCompanyRankingsListProps = {
  docsList: TCompanyRankingExtended[];
  pagination: React.ReactNode;
  emptyText?: string;
  isLoading: boolean;
};
const CompanyRankingsList: React.FC<TCompanyRankingsListProps> = ({
  docsList,
  pagination,
  emptyText,
  isLoading,
}) => {
  return !isLoading ? (
    <Container>
      {docsList?.length ? (
        <>
          <div className='-mx-4 grid grid-cols-1 gap-4 lg:grid-cols-2 '>
            {docsList?.map(currentArticle => {
              const {
                id,
                title,
                url: defaultUrl,
                logo,
                excerpt,
                currentLocale,
                location,
                locales,
                slug,
              } = currentArticle;

              const preparedTActions: TAction = {
                type: EActionType.LinkType,
                props: {
                  ...toLinkProps(currentLocale, {
                    text: title,
                    url: defaultUrl || '',
                    linkType: 'internal',
                    doc: { value: '', relationTo: 'blog-articles' },
                    rel: [],
                  }),
                },
              };
              const url = addJurisdictionSupportItem({
                localeList: locales,
                slug,
                item: preparedTActions,
              });

              return url.type === EActionType.LinkType ? (
                <Link className='w-full' {...url.props} key={id}>
                  <DefaultCard
                    icon={toImageProps(logo)}
                    iconSize={EIconSize.XxxL}
                    size={EDefaultCardLayout.DefaultCardWithBg}
                    title={title}
                    badges={
                      location
                        ? [
                            {
                              variant: ETagVariant.Contained,
                              theme: ETagTheme.Secondary,
                              size: ETagSize.Small,
                              children: location,
                            },
                          ]
                        : []
                    }
                    description={excerpt}
                  />
                </Link>
              ) : null;
            })}
          </div>
          {pagination}
        </>
      ) : (
        emptyText && (
          <p className='mx-auto w-full max-w-[39.5rem] rounded-xl bg-surface-100 p-8 text-center text-lg font-normal text-interface-1000'>
            {emptyText}
          </p>
        )
      )}
    </Container>
  ) : null;
};

export default CompanyRankingsList;
