import { baseTailwindConfig } from '@front/shared';
import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React, { useState } from 'react';
import { useMedia } from 'react-use';

import { Accordion } from '../../atoms/Accordion';
import { ETitleSize, Card } from '../../atoms/Card';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { Container } from '../../sections/Container';

import type { ICardProps } from '../../atoms/Card/Card';

type TAccordionCard = ICardProps & {
  image?: ImageProps | null;
};

export type TAccordionCardsProps = {
  defaultImage?: ImageProps | null;
  isFirstCardOpened?: boolean;
  swapImageSide?: boolean;
  cards: TAccordionCard[];
};

export const AccordionCards: React.FC<TAccordionCardsProps> = props => {
  const { cards = [], defaultImage, isFirstCardOpened, swapImageSide } = props;

  const [openedCardIndex, setOpenedCardIndex] = useState(
    isFirstCardOpened ? 0 : -1,
  );

  const isMatchesXlBreakpoint = useMedia(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
    true,
  );

  if (!Array.isArray(cards)) {
    return null;
  }

  const defaultImageProps = defaultImage ? defaultImage : cards[0].image;
  const openedCard = openedCardIndex === -1 ? null : cards[openedCardIndex];
  const imageProps = openedCard?.image ? openedCard.image : defaultImageProps;

  const onClickAccordionCard = (index: number): void => {
    setOpenedCardIndex(value => (value === index ? -1 : index));
  };

  return (
    <section>
      <Container isPadding={false}>
        <div className='grid gap-y-4 px-0 xl:grid-cols-3 xl:gap-x-4 xl:px-4'>
          <div
            className={clsx('col-span-2', defaultImage ? 'block' : 'hidden', {
              'xl:order-1': swapImageSide,
              'xl:block': !defaultImage,
            })}
          >
            <div className='flex min-h-full items-center justify-center overflow-hidden rounded-[2rem] bg-surface-100'>
              {imageProps && (
                <ImageWithPlaceholder
                  key={openedCardIndex}
                  {...imageProps}
                  alt={imageProps.alt}
                  className='max-w-full'
                />
              )}
            </div>
          </div>

          <div className={clsx('col-span-1 flex flex-col overflow-hidden')}>
            <div
              className={clsx(
                'scrollbar-hidden flex flex-row items-stretch gap-4 overflow-auto scroll-smooth px-4 xl:flex-col xl:px-0',
              )}
            >
              {cards.map((card, index) => {
                const cardImageProps = card.image ? card.image : null;

                return (
                  <div
                    key={card.title}
                    className='flex min-w-[95%] basis-full flex-col gap-3'
                  >
                    {cardImageProps && !defaultImage && (
                      <div className='flex aspect-video items-center justify-center overflow-hidden rounded-[2rem] bg-surface-100 xl:hidden'>
                        <ImageWithPlaceholder
                          key={openedCardIndex}
                          {...cardImageProps}
                          alt={cardImageProps.alt}
                          className='max-w-full rounded-[2rem]'
                        />
                      </div>
                    )}

                    <Accordion
                      isActiveClosable={false}
                      isOpened={
                        !isMatchesXlBreakpoint || index === openedCardIndex
                      }
                      title={card.title || ''}
                      _description={
                        card.description ? (
                          <Card
                            titleSize={card.titleSize as ETitleSize}
                            description={card.description}
                            tags={card.tags}
                            buttons={card.buttons}
                          />
                        ) : null
                      }
                      onClick={
                        !isMatchesXlBreakpoint
                          ? () => {}
                          : () => onClickAccordionCard(index)
                      }
                      showCloseIcon={isMatchesXlBreakpoint}
                      className='flex-1'
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
