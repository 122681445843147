import { ImageProps } from 'next/image';
import React from 'react';

import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder';

export type TCardWithQuoteAuthorProps = {
  icon?: ImageProps | null;
  name?: string;
  position?: string;
};

const CardWithQuoteAuthor: React.FC<TCardWithQuoteAuthorProps> = props => {
  const { icon, name, position } = props;

  if (!icon && !name && !position) {
    return null;
  }

  return (
    <div className='mt-6 flex items-center gap-4'>
      {icon && (
        <ImageWithPlaceholder
          className='h-[3.5rem] w-[3.5rem] object-cover xl:h-[4.5rem] xl:w-[4.5rem]'
          height={56}
          width={56}
          {...icon}
        />
      )}
      {(name || position) && (
        <div className='flex flex-col gap-1'>
          {name && (
            <p className='text-sm font-semibold leading-extra-loose text-interface-1000  md:text-base'>
              {name}
            </p>
          )}
          {position && (
            <p className='text-sm font-normal leading-mega-loose text-interface-800 md:text-base'>
              {position}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default CardWithQuoteAuthor;
