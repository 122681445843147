import clsx from 'clsx';
import { SVGProps } from 'react';

import { TextBlock } from './TextBlock';
import { TTileVariant, TTileProps } from './Tile.types';

const backgroundColorMap: Record<TTileVariant, string> = {
  highlight: '#16D55D',
  primary: '#121212',
  outlined: '#BCC3D1',
};

const textColorMap: Record<TTileVariant, string> = {
  highlight: 'text-white',
  primary: 'text-white',
  outlined: 'text-interface-1000',
};
export const Tile: React.FC<TTileProps & SVGProps<SVGSVGElement>> = ({
  tags,
  title,
  description,
  ...props
}) => {
  const backgroundColor = backgroundColorMap[props.variant || 'primary'];
  const textColorClass = textColorMap[props.variant || 'primary'];

  return (
    <svg width={318} height={163} {...props}>
      {!!tags && tags.length > 0 && (
        <>
          <rect
            width={318}
            height={163}
            rx={16}
            fill='#BCC3D1'
            fillOpacity='0.3'
          />
          <foreignObject
            xmlns='http://www.w3.org/1999/xhtml'
            y={88}
            width='100%'
            height={75}
          >
            <div
              className={clsx(
                'flex',
                'justify-evenly',
                'items-center',
                'w-full',
                'h-full',
              )}
            >
              {tags.map(tag => (
                <div
                  key={tag.id}
                  className='text-center text-sm font-normal not-italic'
                >
                  {tag.text}
                </div>
              ))}
            </div>
          </foreignObject>
        </>
      )}

      {props.variant !== 'outlined' && (
        <rect width={318} height={88} rx={16} fill={backgroundColor}></rect>
      )}

      {props.variant === 'outlined' && (
        <rect
          x={1.5}
          y={1.5}
          width={315}
          height={85}
          rx={16}
          stroke='#121212'
          strokeWidth={1.5}
        ></rect>
      )}

      <TextBlock
        className={textColorClass}
        title={title}
        description={description}
      />
    </svg>
  );
};
