import { VideoCollection } from '@shared/master-types';
import React from 'react';

import { VideoCard } from '../VideoCard';

export type TVideoCarouselProps = {
  videos: VideoCollection[];
  title: string;
  videoItemClickHandler?: (video: VideoCollection) => void;
};

export const VideoCarousel: React.FC<TVideoCarouselProps> = props => {
  const { videos, title, videoItemClickHandler } = props;
  return videos.length ? (
    <div className='flex h-full flex-col rounded rounded-3xl bg-brand-50 opacity-90'>
      <div className='h-[3.875rem] px-6 py-5 text-lg font-semibold leading-normal text-interface-1000'>
        {title}
      </div>

      <div className='relative min-h-[7.5rem] basis-[21.12rem] md:basis-full'>
        <div className='absolute flex h-full max-h-full w-full max-w-full flex-col gap-3 overflow-y-auto overflow-x-clip px-4 pb-4'>
          {videos.map(video => {
            return (
              <VideoCard
                onClick={videoItemClickHandler}
                key={video.id}
                video={video}
              />
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};
