import clsx from 'clsx';
import { ImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

import { EEventCardTheme } from './EventCard.constants';
import { EActionType, TAction } from '../../../types/actions';
import buildDateFormat from '../../../utils/buildDateFormat';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';
import { ETagSize, ETagVariant, Tag } from '../Tag';

export type TEventCardProps = {
  image?: ImageProps | null;
  title: string;
  description?: string;
  startDate: string;
  endDate: string;
  tagLocation: string;
  id?: string;
  url: TAction;
  locale: string;
  theme?: `${EEventCardTheme}`;
};

const EventCard: React.FC<TEventCardProps> = props => {
  const {
    title,
    image,
    startDate,
    endDate,
    description,
    locale,
    tagLocation,
    id,
    url,
    theme = EEventCardTheme.EventCard1,
  } = props;

  const startDatePrepared = new Date(startDate);
  const endDatePrepared = new Date(endDate);

  const formattedStartDate = buildDateFormat(startDatePrepared, locale);

  const formattedEndDate = buildDateFormat(endDatePrepared, locale);

  const eventDate = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    <article>
      {url.type === EActionType.LinkType && (
        <Link {...url.props}>
          <div
            key={id}
            className='mb-6 flex h-auto w-full flex-col gap-4 last:mb-0 md:flex-row lg:mb-0'
          >
            {image ? (
              <div className='w-full overflow-hidden rounded-[1.263rem] md:h-[16rem] md:w-[18.5rem] md:flex-shrink-0 md:rounded-3xl lg:w-[22.5rem] xl:h-[15.25rem] 2xl:w-[25.5rem]'>
                <ImageWithPlaceholder
                  {...image}
                  className='h-[12.875rem] w-full max-w-full shrink-0 object-cover object-center transition-transform duration-500  hover:scale-110 md:h-full'
                  width={302}
                  height={206}
                />
              </div>
            ) : null}
            <div
              className={clsx('w-full rounded-3xl bg-surface-100 p-8', {
                'bg-surface-100 ': theme === EEventCardTheme.EventCard1,
                'bg-surface-50': theme === EEventCardTheme.EventCard2,
                'bg-surface-800': theme === EEventCardTheme.EventCard3,
                'bg-surface-950': theme === EEventCardTheme.EventCard4,
              })}
            >
              {title && (
                <h3
                  className={clsx(
                    'mb-4 text-2xl font-semibold leading-extra-loose  md:line-clamp-1 md:text-4xl md:leading-relaxed',
                    {
                      'text-interface-1000 ':
                        theme === EEventCardTheme.EventCard1 ||
                        theme === EEventCardTheme.EventCard2,
                      'text-interface-50':
                        theme === EEventCardTheme.EventCard3 ||
                        theme === EEventCardTheme.EventCard4,
                    },
                  )}
                >
                  {title}
                </h3>
              )}
              {description && (
                <p
                  className={clsx(
                    'line-clamp-6 pr-2 text-base font-normal leading-mega-loose md:line-clamp-3 2xl:line-clamp-2',
                    {
                      'text-interface-800':
                        theme === EEventCardTheme.EventCard1 ||
                        theme === EEventCardTheme.EventCard2,
                      'text-interface-50/80':
                        theme === EEventCardTheme.EventCard3 ||
                        theme === EEventCardTheme.EventCard4,
                    },
                  )}
                >
                  {description}
                </p>
              )}
              <div
                className={clsx(
                  'mt-4 flex flex-col items-start gap-2',
                  !image
                    ? 'md:flex-row md:flex-wrap md:items-center'
                    : 'xl:flex-row xl:flex-wrap xl:items-center',
                )}
              >
                {tagLocation && (
                  <Tag
                    size={ETagSize.Small}
                    children={tagLocation}
                    variant={ETagVariant.Contained}
                  />
                )}

                {eventDate && (
                  <p
                    className={clsx('text-xs  leading-relaxed', {
                      'font-medium text-interface-800':
                        theme === EEventCardTheme.EventCard1 ||
                        theme === EEventCardTheme.EventCard2,
                      'font-normal text-interface-50/80':
                        theme === EEventCardTheme.EventCard3 ||
                        theme === EEventCardTheme.EventCard4,
                    })}
                  >
                    {eventDate}
                  </p>
                )}
              </div>
            </div>
          </div>
        </Link>
      )}
    </article>
  );
};

export default EventCard;
