import Link, { LinkProps } from 'next/link';
import React from 'react';

export type TNewsCardContentPart = {
  title: string;
  description?: string;
  link: LinkProps;
};

const NewsCardContentPart: React.FC<TNewsCardContentPart> = props => {
  const { title, description, link } = props;

  if (!link.href) {
    return null;
  }

  return (
    <Link className='block' {...link}>
      {title && (
        <h3 className='line-clamp-2	text-4xl font-semibold leading-relaxed text-interface-1000'>
          {title}
        </h3>
      )}
      {description && (
        <p className='mt-4 line-clamp-3 text-base leading-mega-loose text-interface-800 md:line-clamp-2'>
          {description}
        </p>
      )}
    </Link>
  );
};

export default NewsCardContentPart;
