import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React, { useState } from 'react';

import { IconQuote } from '../../icons';
import { Container, EContainerVariant } from '../../sections/Container';

type TCustomerReview = {
  author?: string;
  text?: React.JSX.Element | string | null;
  position?: string;
  company?: string;
  image?: ImageProps | null;
};

type TCustomerReviewsProps = {
  reviews: TCustomerReview[];
};

export const CustomerReviews: React.FC<TCustomerReviewsProps> = ({
  reviews,
}) => {
  const [stateReviews, setStateReviews] = useState(reviews);
  const firstReview = stateReviews[0];
  const restReviews = stateReviews.slice(1);

  const onClickReview = (index: number): void => {
    const newReviews = [...stateReviews];
    newReviews[0] = stateReviews[index + 1];
    newReviews[index + 1] = stateReviews[0];
    setStateReviews(newReviews);
  };

  const scrollBarClasses =
    'scrollbar-thin scrollbar-w-1 scroll-smooth scrollbar-track-rounded-full scrollbar-thumb-rounded-full';

  return (
    <Container variant={EContainerVariant.Full}>
      <div className='flex flex-col gap-6 2xl:flex-row 2xl:gap-8'>
        <div className='flex flex-1 flex-col justify-between gap-4 rounded-3xl border-2 border-surface-200 p-[14px] md:max-h-[392px] md:flex-row'>
          <div className='flex flex-col gap-4 py-2 ps-2 md:py-4 md:ps-4'>
            <IconQuote width={32} height={32} className='text-interface-500' />
            <div className='flex-1'>
              <div className='text-3xl leading-loose text-interface-1000 md:max-h-[162px] md:overflow-hidden md:text-ellipsis xl:max-h-[175px] xl:text-4xl xl:leading-relaxed'>
                {firstReview.text}
              </div>
            </div>

            <div className='flex flex-col gap-1'>
              <div className='text-lg font-semibold leading-extra-loose text-interface-1000'>
                {firstReview.author}
              </div>

              <div className='text-lg font-medium leading-extra-loose text-interface-700'>
                <span>{firstReview.position}</span>
                {firstReview.position && firstReview.company ? (
                  <span>, </span>
                ) : null}
                <span>{firstReview.company}</span>
              </div>
            </div>
          </div>

          <div className='h-auto w-full shrink-0 overflow-hidden rounded-2xl md:h-[360px] md:w-[280px] lg:w-[312px]'>
            {firstReview.image ? (
              <Image
                {...firstReview.image}
                alt={firstReview.image.alt}
                className='object-cover object-top md:h-[360px] md:w-[280px] lg:w-[312px]'
              />
            ) : null}
          </div>
        </div>

        <div
          className={clsx(
            '-mx-4 flex shrink-0 overflow-x-auto px-4 md:mx-0 md:justify-center md:overflow-visible md:px-0 2xl:w-[368px]',
            scrollBarClasses,
          )}
        >
          <div className='flex gap-2 md:gap-4 2xl:flex-wrap 2xl:gap-6'>
            {restReviews.map((review, index) =>
              review.image ? (
                <button
                  key={index}
                  onClick={() => onClickReview(index)}
                  className='h-[120px] w-[112px] shrink-0 overflow-hidden rounded-2xl hover:opacity-80 2xl:h-[184px] 2xl:w-[172px] 2xl:rounded-3xl'
                  title={review.author}
                >
                  <Image
                    {...review.image}
                    alt={review.image.alt}
                    className='h-[120px] w-[112px] object-cover object-top 2xl:h-[184px] 2xl:w-[172px]'
                  />
                </button>
              ) : null,
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
