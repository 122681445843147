export const enum EGridCardImagePaddings {
  HorizontalAndBottom = 'HorizontalAndBottom',
  None = 'None',
}

export const enum EGridCardPosition {
  Top = 'Top',
  Bottom = 'Bottom',
  TopRight = 'TopRight',
}
