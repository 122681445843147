import { cva } from 'cva';
import React from 'react';

import { Button, EButtonVariant } from '../../../atoms/Button';
import { EHorizontalCardBgV2Theme } from '../constants';

import type { THorizontalCardBgV2Props } from '../types';

const buttonCva = cva('', {
  variants: {
    variant: {
      [EButtonVariant.Contained]: '',
      [EButtonVariant.Outlined]: '',
      [EButtonVariant.Text]: '',
      [EButtonVariant.Image]: '',
      [EButtonVariant.OutlineRounded]: '',
      [EButtonVariant.ContainedRounded]: '',
    },
    theme: {
      [EHorizontalCardBgV2Theme.Theme1]: '',
      [EHorizontalCardBgV2Theme.Theme2]: '',
      [EHorizontalCardBgV2Theme.HorizontalCardBgV2ForexBroker]: '',
      [EHorizontalCardBgV2Theme.HorizontalCardBgV2B2Prop]: '',
      [EHorizontalCardBgV2Theme.HorizontalCardBgV2CryptoBroker]: '',
    },
  },
  compoundVariants: [
    // HorizontalCardBgV2ForexBroker
    {
      variant: [EButtonVariant.Contained, EButtonVariant.ContainedRounded],
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2ForexBroker,
      className: 'bg-[rgba(51,205,118,0.12)] text-[rgba(51,205,118,0.9)]',
    },
    {
      variant: [EButtonVariant.Outlined, EButtonVariant.OutlineRounded],
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2ForexBroker,
      className:
        'border border-[rgba(51,205,118,0.9)] text-[rgba(51,205,118,0.9)]',
    },
    {
      variant: EButtonVariant.Text,
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2ForexBroker,
      className: 'text-[rgba(51,205,118,0.9)]',
    },
    // HorizontalCardBgV2B2Prop
    {
      variant: [EButtonVariant.Contained, EButtonVariant.ContainedRounded],
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2B2Prop,
      className: 'bg-[rgba(45,72,214,0.12)] text-[rgba(45,72,214,0.9)]',
    },
    {
      variant: [EButtonVariant.Outlined, EButtonVariant.OutlineRounded],
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2B2Prop,
      className:
        'border border-[rgba(45,72,214,0.9)] text-[rgba(45,72,214,0.9)]',
    },
    {
      variant: EButtonVariant.Text,
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2B2Prop,
      className: 'text-[rgba(45,72,214,0.9)]',
    },
    // HorizontalCardBgV2CryptoBroker
    {
      variant: [EButtonVariant.Contained, EButtonVariant.ContainedRounded],
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2CryptoBroker,
      className: 'bg-[rgba(59,140,255,0.12)] text-[rgba(59,140,255,0.9)]',
    },
    {
      variant: [EButtonVariant.Outlined, EButtonVariant.OutlineRounded],
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2CryptoBroker,
      className:
        'border border-[text-[rgba(59,140,255,0.9)]] text-[rgba(59,140,255,0.9)]',
    },
    {
      variant: EButtonVariant.Text,
      theme: EHorizontalCardBgV2Theme.HorizontalCardBgV2CryptoBroker,
      className: 'text-[rgba(59,140,255,0.9)]',
    },
  ],
});

const Buttons: React.FC<
  Pick<THorizontalCardBgV2Props, 'buttons' | 'theme'>
> = props => {
  const { buttons, theme } = props;

  if (!buttons?.length) {
    return null;
  }

  return (
    <div className='flex flex-row flex-wrap items-center gap-6 pt-7'>
      {buttons.map((button, index) => (
        <Button
          key={index}
          {...button}
          className={buttonCva({ theme, variant: button.variant })}
        >
          {button.children}
        </Button>
      ))}
    </div>
  );
};

export default Buttons;
