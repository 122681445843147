import clsx from 'clsx';
import { cva } from 'cva';
import { motion, useTransform } from 'framer-motion';
import React, { useCallback, useEffect } from 'react';

import Blocks from './components/Blocks';
import Buttons from './components/Buttons';
import Description from './components/Description';
import Eyebrows from './components/Eyebrows';
import Image from './components/Image';
import Spaces from './components/Spaces';
import Title from './components/Title';
import { EBannerCardBgV2Theme, EBannerCardsV2AlignImage } from './constants';
import { Icon } from '../../atoms/Icon';
import { EIconSize } from '../../atoms/Icon/Icon.constants';

import type { TBannerCardBgV2Props } from './types';

export const cardCVA = cva(
  'flex flex-wrap rounded-3xl lg:flex-nowrap 2xl:gap-4 overflow-hidden',
  {
    variants: {
      theme: {
        [EBannerCardBgV2Theme.Theme1]: 'bg-surface-100',
        [EBannerCardBgV2Theme.Theme2]: 'bg-surface-50',
      },
      isAnimationEnabled: {
        true: 'border-2 border-surface-50-input',
        false: '',
      },
      alignImage: {
        [EBannerCardsV2AlignImage.Left]: 'lg:flex-row flex-col-reverse',
        [EBannerCardsV2AlignImage.Right]: 'flex-col-reverse	lg:flex-row-reverse',
      },
    },
  },
);

const treashold = 0.95;

const BannerCardBgV2: React.FC<TBannerCardBgV2Props> = props => {
  const {
    blocks,
    eyebrows,
    icon,
    description,
    title,
    buttons,
    space,
    image,
    theme,
    alignImage,
    /* animations */
    idx,
    isAnimationEnabled,
    progress,
    rangeWidth,
    rangeTitle,
    targetOffset,
    targetMinWidth,
    targetMaxWidth,
    hideSpaceOnMobile,
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  const [calculatedHeight, setCalculatedHeight] = React.useState<number>(0);

  const titleHide = useTransform(progress, rangeTitle, [1, 0]);
  const width = useTransform(progress, rangeWidth, [
    targetMaxWidth,
    targetMinWidth,
  ]);

  const [isFinished, setIsFinished] = React.useState(false);

  useEffect(() => {
    if (!isAnimationEnabled) {
      return;
    }

    function updateOpacity(): void {
      const value = titleHide.get() < treashold;

      if (isFinished !== value) {
        setIsFinished(value);
      }
    }

    const unsubscribe = titleHide.on('change', updateOpacity);

    return () => {
      unsubscribe();
    };
  }, [idx, titleHide, isFinished, isAnimationEnabled]);

  const handleOnLoad = useCallback(
    (
      e: React.SyntheticEvent<HTMLImageElement, Event> & {
        target: HTMLImageElement;
      },
    ) => {
      if (isAnimationEnabled) {
        setCalculatedHeight(Number(image?.height) || e.target.clientHeight);
      }
    },
    [isAnimationEnabled, image?.height],
  );

  return (
    <motion.div
      style={
        isAnimationEnabled
          ? {
              position: 'relative',
              top: targetOffset,
              transformOrigin: 'top center',
              width,
            }
          : {}
      }
    >
      <div
        className={cardCVA({ theme, isAnimationEnabled, alignImage })}
        style={{
          minHeight: calculatedHeight ? calculatedHeight : undefined,
        }}
        ref={ref}
      >
        <Image image={image} onLoad={handleOnLoad} />

        <div
          className={clsx(
            'flex flex-col justify-between gap-8 p-6 md:gap-6 lg:max-w-[22.5rem] lg:gap-8 xl:w-[30.5rem] xl:min-w-[30.5rem] xl:p-8 2xl:w-[25rem] 2xl:min-w-[25rem]',
            {
              'xl:pt-4': isFinished,
            },
          )}
        >
          <div className='flex flex-col gap-8 md:gap-6 lg:gap-8'>
            {(icon || title || description) && (
              <div className='flex flex-col gap-4'>
                <Icon icon={icon} size={EIconSize.XxxL} />

                <Eyebrows eyebrows={eyebrows} />

                <Title
                  title={title}
                  shrink={isAnimationEnabled && isFinished}
                />

                <Description description={description} />
              </div>
            )}

            <Blocks blocks={blocks} />
          </div>

          {buttons?.length || space?.length ? (
            <div
              className={clsx(
                'flex-col gap-8 empty:hidden md:gap-6 lg:gap-8',
                hideSpaceOnMobile && !buttons?.length
                  ? 'hidden lg:flex'
                  : 'flex',
              )}
            >
              <Buttons buttons={buttons} />

              <Spaces space={space} hideSpaceOnMobile={hideSpaceOnMobile} />
            </div>
          ) : null}
        </div>
      </div>
    </motion.div>
  );
};

export default BannerCardBgV2;
