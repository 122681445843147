import React from 'react';

import Container from '../../sections/Container/Container';
import {
  HorizontalCardBgV2,
  THorizontalCardBgV2Props,
} from '../HorizontalCardBgV2';

export type THorizontalCardsBgV2Props = {
  cards?: THorizontalCardBgV2Props[] | null;
  hasDivider: boolean;
};

const HorizontalCardsBgV2: React.FC<THorizontalCardsBgV2Props> = ({
  cards,
  hasDivider,
}) => {
  if (!cards?.length) {
    return null;
  }

  return (
    <section>
      <Container>
        <div className='flex flex-col justify-center gap-4'>
          {cards.map((card, index, arr) => (
            <React.Fragment key={card.id}>
              <HorizontalCardBgV2 {...card} />
              {hasDivider && index !== arr.length - 1 && (
                <div className='h-[0px] w-full border border-surface-150'></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default HorizontalCardsBgV2;
