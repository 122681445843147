import clsx from 'clsx';
import React, { Fragment } from 'react';

export type TTableBlockRow = {
  cell1?: string;
  cell2?: string;
};

export type TTableBlock = {
  title: string;
  rows: TTableBlockRow[];
};

type TTableBlockProps = {
  block: TTableBlock;
  hasDivider?: boolean;
  dividerClassName?: string;
};

export const TableBlock: React.FC<TTableBlockProps> = ({
  block,
  hasDivider,
  dividerClassName,
}) => {
  return (
    <Fragment>
      {hasDivider && <div className={clsx('my-6 h-px', dividerClassName)} />}

      <div className='flex flex-col gap-4 xl:flex-row'>
        <div className='flex-1 py-2 text-lg leading-relaxed lg:text-3xl lg:leading-loose'>
          {block.title}
        </div>

        <div className='-my-2 xl:w-[272px]'>
          <table className=' w-full table-fixed border-separate border-spacing-x-0 border-spacing-y-2 text-3xl leading-loose'>
            <tbody>
              {block.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className='w-[108px] py-2 pl-0 pr-2 align-top text-lg font-semibold leading-extra-loose lg:text-3xl lg:leading-loose'>
                    <div className='[overflow-wrap:anywhere]'>{row.cell1}</div>
                  </td>
                  <td className='py-2 pl-0 pr-2 align-top text-lg leading-relaxed [overflow-wrap:anywhere] lg:text-3xl lg:leading-loose'>
                    {row.cell2}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};
