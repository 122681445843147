import { cva } from 'cva';
import React from 'react';

import { Icon, ESimpleIconSize } from '../../../atoms/Icon';

import type { ImageProps } from 'next/image';

const tabCva = cva(
  'rounded-3xl min-w-[106px] shrink-0 flex flex-col justify-between h-[104px]',
  {
    variants: {
      active: {
        true: 'p-4 bg-surface-50',
        false:
          'border-control-250 border-2 border-dashed p-[14px] hover:border-interface-1000',
      },
    },
  },
);

type TSquareTabProps = {
  title: string;
  icon?: ImageProps | null;
  active?: boolean;
  onClick?: () => void;
};

export const SquareTab: React.FC<TSquareTabProps> = ({
  title,
  icon,
  active,
  onClick,
}) => {
  const content = (
    <>
      <div>{icon ? <Icon icon={icon} size={ESimpleIconSize.L} /> : null}</div>
      <div className='text-base font-medium leading-loose text-interface-1000'>
        {title}
      </div>
    </>
  );

  if (onClick && !active) {
    return (
      <button className={tabCva({ active })} onClick={onClick}>
        {content}
      </button>
    );
  }

  return <div className={tabCva({ active })}>{content}</div>;
};
