import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';

import { TTreeDiagramProps } from './TreeDiagram.types';
import { Tile } from './components/Tile';
import { ReactComponent as TreeDiagramSVG } from '../../../../../public/icons/components/diagram/tree-diagram.svg';

export const TreeDiagram: React.FC<TTreeDiagramProps> = props => {
  const { row1, row2, row3, row4 } = props;
  const refSvgWrapper = React.useRef<HTMLDivElement>(null);

  // set inside scroll of container to center
  const recenterScroll = useCallback(() => {
    if (refSvgWrapper.current) {
      const { scrollWidth, scrollHeight, clientWidth, clientHeight } =
        refSvgWrapper.current;
      refSvgWrapper.current.scrollLeft = (scrollWidth - clientWidth) / 2;
      refSvgWrapper.current.scrollTop = (scrollHeight - clientHeight) / 2;
    }
  }, []);

  useEffect(() => {
    recenterScroll();

    const handleResize = (): void => {
      recenterScroll();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [recenterScroll]);

  return (
    <div
      className={clsx(
        'flex flex-shrink-0 flex-grow-0 items-center justify-center',
      )}
    >
      <div
        className={clsx('overflow-scroll md:overflow-auto')}
        ref={refSvgWrapper}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1108'
          height='621'
          viewBox='0 0 1108 621'
          fill='none'
        >
          {/* row 1 */}
          <Tile x='395' y='16' {...row1.tile_1_1} />
          {/* row 2 */}
          <Tile x='32' y='228' {...row2.tile_2_1} />
          <Tile x='395' y='228' {...row2.tile_2_2} />
          <Tile x='758' y='228' {...row2.tile_2_3} />
          {/* row 3 */}
          <Tile x='395' y='360' {...row3.tile_3_1} />
          {/* row 4 */}
          <Tile x='395' y='483' {...row4.tile_4_1} />
          {/* tree diagram: arrows and connections */}
          <TreeDiagramSVG />
        </svg>
      </div>
    </div>
  );
};
