import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import {
  EHorizontalCardLayout,
  EHorizontalCardBackground,
} from './HorizontalCard.constants';
import { Card, ECardTextColor, ETitleSize, ICardProps } from '../../atoms/Card';

type THorizontalCardProps = ICardProps & {
  size?: EHorizontalCardLayout;
  image?: ImageProps | null;
  swapImageSide?: boolean;
  background?: EHorizontalCardBackground;
};

const HorizontalCard: React.FC<THorizontalCardProps> = props => {
  const { size, swapImageSide, background, ...rest } = props;

  let cardStyles = '';
  let cardTextColor: ECardTextColor | null = ECardTextColor.Dark;
  let cardBackground = ' bg-surface-100';
  if (background === EHorizontalCardBackground.Dark) {
    cardTextColor = ECardTextColor.Light;
    cardBackground = ' bg-control-1000';
  } else if (background === EHorizontalCardBackground.White) {
    cardBackground = ' bg-surface-50';
  } else if (background === EHorizontalCardBackground.Brand) {
    cardBackground = ' bg-brand-500';
    cardTextColor = ECardTextColor.Light;
  } else if (background === EHorizontalCardBackground.Theme5) {
    cardBackground = '';
    cardTextColor = null;
    cardStyles =
      'bg-surface-800 text-interface-50/80 prose-h3:text-interface-50';
  }

  if (size === EHorizontalCardLayout.HorizontalCard) {
    return <Card isHorizontalCard titleSize={ETitleSize.Medium} {...rest} />;
  }

  return (
    <div
      className={clsx(
        'rounded-3xl p-8 2xl:rounded-[2rem]' + cardBackground,
        swapImageSide ? '2xl:flex-row-reverse' : '2xl:flex-row',
        cardStyles,
        'mx-4 2xl:mx-0',
      )}
    >
      <Card
        isHorizontalCard
        cardTextColor={cardTextColor}
        titleSize={ETitleSize.Medium}
        {...rest}
      />
    </div>
  );
};

export default HorizontalCard;
