import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import {
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
  IconCheck,
  IconCross,
  TAction,
} from '../../../index';
import { Button } from '../Button';
import { Popover, PopoverTrigger, PopoverContent } from '../Popover';
import { Tag, ETagSize, ETagTheme, ETagVariant } from '../Tag';

export type TPricingColProps = {
  tariff: string;
  price: string;
  tip?: string;
  priceInterval: string;
  actions?: TAction[];
  points: {
    item?: string;
    isDisabled?: boolean;
    subtext?: string;
  }[];
};

const PricingCol: React.FC<TPricingColProps> = props => {
  const { tariff, price, priceInterval, points, tip, actions } = props;

  return (
    <div className='min-w-[20.75rem] 2xl:w-full 2xl:min-w-0 2xl:max-w-[25.5rem]'>
      <div className='relative rounded-2xl bg-surface-100 p-8'>
        {tip && (
          <Popover placement='bottom-end'>
            <PopoverTrigger className='absolute right-6 top-6 flex h-6 w-6 items-center justify-center rounded-md bg-brand-250 text-sm font-medium text-brand-500'>
              !
            </PopoverTrigger>
            <PopoverContent className='max-w-[12rem] rounded-xl bg-surface-50-tooltip bg-opacity-[.35] p-4 text-xs leading-loose text-interface-1000 text-opacity-80 backdrop-blur-xl'>
              {tip}
            </PopoverContent>
          </Popover>
        )}
        <Tag
          size={ETagSize.Small}
          variant={ETagVariant.Text}
          theme={ETagTheme.Primary}
        >
          {tariff}
        </Tag>
        <div className='mb-6 mt-2 flex items-baseline gap-3 text-7xl font-semibold leading-snug text-interface-1000'>
          {price}
          {priceInterval && (
            <span className='text-base font-medium leading-relaxed text-interface-500'>
              / {priceInterval}
            </span>
          )}
        </div>
        <div className='grid'>
          {actions?.map((action, index) => (
            <div className='grid 2xl:grid' key={index}>
              {action.type === 'button' && (
                <Button
                  {...action.props}
                  variant={EButtonVariant.Contained}
                  theme={EButtonTheme.Primary}
                  size={EButtonSize.Large}
                />
              )}
              {action.type === 'link' && (
                <Link
                  className='flex items-center justify-center gap-2.5 rounded-2xl bg-brand-500 px-10 py-5 text-center text-base font-medium leading-tight text-brand-50/90 transition last:border last:border-brand-500 last:bg-transparent last:text-brand-500 hover:bg-brand-550 hover:text-brand-50'
                  {...action.props}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='mt-8'>
        <ul className='grid gap-6 px-8'>
          {points.map((point, index) => (
            <li key={index}>
              <span className='grid grid-cols-[0.75rem_auto] items-center gap-x-2 gap-y-1'>
                {point.isDisabled ? (
                  <IconCross className='aspect-square h-3 w-3 text-control-600' />
                ) : (
                  <IconCheck className='aspect-square h-3 w-3 text-brand-500' />
                )}
                <span
                  className={clsx(
                    'col-start-2 col-end-3 row-start-1 row-end-2 text-base font-medium leading-relaxed',
                    point.isDisabled
                      ? 'text-control-600'
                      : 'text-interface-1000',
                  )}
                >
                  {point.item}
                </span>
                <span className='col-start-2 col-end-3 row-start-2 row-end-3 text-sm leading-loose text-interface-800'>
                  {point.subtext}
                </span>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PricingCol;
