import clsx from 'clsx';
import React from 'react';

import {
  ETitleCardBasicAlign,
  ETitleCardBasicAlignIcon,
  ETitleCardBasicTruncateText,
} from './TitleCardBasic.constants';
import { Icon } from '../Icon';
import { EIconSize } from '../Icon/Icon.constants';
import { TitlePart } from '../TitlePart';
import { ETitlePartsSize } from '../TitlePart/TitlePart.constants';

import type { ImageProps } from 'next/image';

export type TTitleCardBasicProps = {
  leftIcon: ImageProps | null;
  rightIcon: ImageProps | null;
  title: React.ReactNode;
  description?: string | null;
  truncateText: ETitleCardBasicTruncateText;
  alignIcons: ETitleCardBasicAlignIcon;
  alignContent?: ETitleCardBasicAlign;
};

const TitleCardBasic: React.FC<TTitleCardBasicProps> = props => {
  const {
    leftIcon,
    rightIcon,
    title,
    description,
    alignContent = ETitleCardBasicAlign.Left,
    truncateText,
    alignIcons,
  } = props;

  const iconClasses = clsx('shrink-0 overflow-hidden', {
    'self-start': alignIcons === ETitleCardBasicAlignIcon.Top,
  });

  return (
    <div className={clsx('flex max-w-full items-center gap-2')}>
      {leftIcon && (
        <div className={iconClasses}>
          <Icon icon={leftIcon} size={EIconSize.L} />
        </div>
      )}

      <div
        className={clsx(
          'inline-flex flex-1 flex-col gap-1 overflow-hidden md:w-min',
        )}
      >
        <div
          className={clsx(
            'max-w-full prose-p:overflow-hidden prose-p:overflow-ellipsis md:whitespace-nowrap',
          )}
        >
          <TitlePart size={ETitlePartsSize.Xs}>{title}</TitlePart>
        </div>

        {description && (
          <div
            className={clsx(
              'text-control-800 max-w-full text-sm leading-mega-loose',
              {
                'line-clamp-1 overflow-ellipsis':
                  truncateText === ETitleCardBasicTruncateText.Yes,
                'md:text-center': alignContent === ETitleCardBasicAlign.Center,
              },
            )}
          >
            {description}
          </div>
        )}
      </div>

      {rightIcon && (
        <div className={iconClasses}>
          <Icon icon={rightIcon} size={EIconSize.L} />
        </div>
      )}
    </div>
  );
};

export default TitleCardBasic;
