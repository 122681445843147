import clsx from 'clsx';
import React from 'react';

import { TTextBlockProps } from './TextBlock.types';

export const TextBlock: React.FC<TTextBlockProps> = ({
  title,
  description,
  className,
  ...props
}) => {
  if (!title && !description) {
    return null;
  }

  return (
    <foreignObject
      xmlns='http://www.w3.org/1999/xhtml'
      y='0%'
      width='100%'
      height={88}
      {...props}
    >
      <div
        className={clsx(
          'flex h-full flex-col items-center justify-center p-4 text-center',
          className,
        )}
      >
        {description && <div className='text-base'>{description}</div>}
        {title && (
          <div className='text-2xl font-semibold leading-relaxed'>{title}</div>
        )}
      </div>
    </foreignObject>
  );
};
