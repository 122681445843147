import { baseTailwindConfig } from '@front/shared';
import React from 'react';

import { NoScrollRowComponent } from './CardWithLogoRowVariants/NoScrollRowComponent';
import { YesScrollRowComponent } from './CardWithLogoRowVariants/YesScrollRowComponent';
import { useBetterMediaQuery } from '../../../hooks/useBetterMediaQuery';
import {
  ECardWithLogoRowHorizontalScroll,
  TCardWithLogo,
} from '../../atoms/CardWithLogo';

export type TCardWithLogoRowProps = {
  horizontalScroll?: `${ECardWithLogoRowHorizontalScroll}`;
  cards: TCardWithLogo[];
};

export const CardWithLogoRow: React.FC<TCardWithLogoRowProps> = props => {
  const { cards = [], horizontalScroll = ECardWithLogoRowHorizontalScroll.No } =
    props;

  const isXl = useBetterMediaQuery(
    `(min-width: ${baseTailwindConfig.screens.xl})`,
  );

  return cards.length ? (
    <>
      {horizontalScroll === ECardWithLogoRowHorizontalScroll.No && (
        <NoScrollRowComponent cards={cards} />
      )}
      {horizontalScroll === ECardWithLogoRowHorizontalScroll.Yes && (
        <YesScrollRowComponent cards={cards} />
      )}
      {horizontalScroll === ECardWithLogoRowHorizontalScroll.YesXlXxl &&
        (isXl ? (
          <YesScrollRowComponent cards={cards} />
        ) : (
          <NoScrollRowComponent cards={cards} />
        ))}
      {horizontalScroll === ECardWithLogoRowHorizontalScroll.YesLgMdSm &&
        (isXl ? (
          <NoScrollRowComponent cards={cards} />
        ) : (
          <YesScrollRowComponent cards={cards} />
        ))}
    </>
  ) : null;
};
