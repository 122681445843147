import clsx from 'clsx';
import React from 'react';

import { EEyebrowSize, EEyebrowVariant, Eyebrow } from '../../../atoms/Eyebrow';
import { EBrandCardBgV2AlignContent } from '../constants';

type TEyebrowsProps = {
  eyebrows?: string[];
  className?: string;
  alignContent?: EBrandCardBgV2AlignContent;
};

export const Eyebrows: React.FC<TEyebrowsProps> = ({
  eyebrows,
  alignContent,
  className,
}) => {
  if (!eyebrows?.length) {
    return null;
  }

  return (
    <div
      className={clsx('flex flex-wrap gap-x-6 gap-y-4', {
        'justify-center text-center':
          alignContent === EBrandCardBgV2AlignContent.Center,
      })}
    >
      {eyebrows.map((eyebrow, index) => (
        <Eyebrow
          key={index}
          size={EEyebrowSize.Medium}
          variant={EEyebrowVariant.TextTracking}
          className={className}
        >
          {eyebrow}
        </Eyebrow>
      ))}
    </div>
  );
};
