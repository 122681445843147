import clsx from 'clsx';
import React, { useState } from 'react';

import FeatureTile from '../../atoms/FeatureTile';
import { Container } from '../../sections/Container';

export interface ITabbedCardsProps {
  tabs: {
    tab: { name: string };
    cards: React.ComponentPropsWithoutRef<typeof FeatureTile>[];
  }[];
}

const TabbedCards: React.FC<ITabbedCardsProps> = props => {
  const { tabs = [] } = props;

  const [tab, setTab] = useState(0);

  const handleCardClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    (e.target as HTMLElement).scrollIntoView({
      block: 'nearest',
      inline: 'center',
    });
  };

  return (
    <section className='grid gap-6'>
      <div className='scrollbar-hidden flex gap-6 overflow-auto scroll-smooth px-4 text-lg font-medium leading-normal text-control-600 lg:gap-8 2xl:justify-center'>
        {tabs.map((t, i) => (
          <button
            key={i}
            type='button'
            className={clsx(
              'transition hover:text-brand-550',
              i === tab && 'text-brand-500',
            )}
            onClick={() => setTab(i)}
          >
            {t.tab.name}
          </button>
        ))}
      </div>
      <div className='scrollbar-hidden snap-x snap-mandatory overflow-auto scroll-smooth'>
        <Container>
          <div className='flex w-full gap-2 lg:gap-4'>
            {tabs[tab].cards.map((card, i) => (
              <div
                key={i}
                className={clsx(
                  'w-[calc(100%_-_0.75rem)] shrink-0 cursor-pointer snap-center lg:w-[calc((100%_-_3rem)_/_2)] 2xl:w-[calc((100%_-_2rem)_/_3)]',
                  i === tabs[tab].cards.length - 1 && 'pr-4 lg:pr-3',
                )}
                onClick={handleCardClick}
              >
                <FeatureTile key={i} {...card} />
              </div>
            ))}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default TabbedCards;
