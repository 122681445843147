import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntersection } from 'react-use';

import { EPartnersWidgetTabsIconPosition } from './PartnersWidget.constants';
import {
  ESelectBackgroundVariant,
  ESelectVariant,
  Option,
  Select,
} from '../../atoms/Select';
import { ESolidTabSize, ESolidTabTheme, SolidTab } from '../../atoms/SolidTab';

export const PARTNERS_WIDGET_ALL_TAB_VALUE = 'all';

export type TPartnersWidgetTab = {
  text: string;
  iconProps?: ImageProps | null;
};

export type TPartnersWidgetTabsProps = {
  tabs: TPartnersWidgetTab[];
  selectedTabId?: string;
  allTabLabel?: string;
  tabsStickyThreshold?: number;
  tabsIconPosition?: EPartnersWidgetTabsIconPosition;
  wrapperRef: RefObject<HTMLDivElement>;
  onChangeTab: (value?: string) => void;
};

export const PartnersWidgetTabs: React.FC<TPartnersWidgetTabsProps> = props => {
  const {
    tabs = [],
    allTabLabel,
    tabsStickyThreshold,
    tabsIconPosition,
    selectedTabId,
    wrapperRef,
    onChangeTab,
  } = props;

  const stickyRootMargin = tabsStickyThreshold ? tabsStickyThreshold + 3 : 0;

  const [showTabsAsSelect, setShowTabsAsSelect] = useState(false);
  const tabsRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(tabsRef, {
    threshold: 1,
    rootMargin: `-${stickyRootMargin}px 0px 0px 0px`,
  });

  const isSticked =
    tabsStickyThreshold &&
    (intersection?.boundingClientRect.top || 0) <= stickyRootMargin;

  const options = (
    allTabLabel
      ? [{ label: allTabLabel, value: PARTNERS_WIDGET_ALL_TAB_VALUE }]
      : []
  ).concat(tabs.map(tab => ({ value: tab.text, label: tab.text })));
  const selectedIndex = options.findIndex(
    option => option.value === selectedTabId,
  );

  const checkTabsSize = useCallback(() => {
    if (!tabsRef.current || !wrapperRef.current) {
      return;
    }

    const tabsRect = tabsRef.current.getBoundingClientRect();
    const wrapperRect = wrapperRef.current.getBoundingClientRect();

    if (tabsRect.width >= wrapperRect.width - 32) {
      if (!showTabsAsSelect) {
        setShowTabsAsSelect(true);
      }
    } else if (showTabsAsSelect) {
      setShowTabsAsSelect(false);
    }
  }, [showTabsAsSelect, wrapperRef]);

  useEffect(() => {
    checkTabsSize();

    window.addEventListener('resize', checkTabsSize);
    return () => window.removeEventListener('resize', checkTabsSize);
  }, [checkTabsSize]);

  return (
    <>
      <div
        className={clsx('mx-auto w-full lg:max-w-[38rem]', {
          'lg:hidden': !showTabsAsSelect,
        })}
      >
        <Select
          variant={ESelectVariant.Widget}
          backgroundVariant={ESelectBackgroundVariant.Widget}
          value={selectedIndex}
          options={options}
          onChange={index => {
            onChangeTab(index ? options[index]?.value : undefined);
          }}
        >
          {options.map(option => (
            <Option
              key={option.value}
              label={option.label}
              variant={ESelectVariant.Widget}
            >
              <span className='whitespace-nowrap'>{option.label}</span>
            </Option>
          ))}
        </Select>
      </div>

      <div
        ref={tabsRef}
        className={clsx(
          // hide with invisible to allow resize calculation of hidden tabs
          'invisible absolute left-0 z-10 mx-auto flex max-w-[100vw] overflow-hidden rounded-[2rem]',
          isSticked ? 'bg-control-1000' : '',
          showTabsAsSelect ? '' : 'lg:visible lg:sticky',
        )}
        style={{ top: `${tabsStickyThreshold}px` }}
      >
        {allTabLabel && (
          <SolidTab
            theme={isSticked ? ESolidTabTheme.Sticked : ESolidTabTheme.Ordinary}
            size={ESolidTabSize.Medium}
            isActive={selectedTabId === PARTNERS_WIDGET_ALL_TAB_VALUE}
            value={PARTNERS_WIDGET_ALL_TAB_VALUE}
            label={allTabLabel}
            onClick={onChangeTab}
          />
        )}

        {tabs.map(tab => {
          const icon = tab.iconProps ? (
            <Image
              {...tab.iconProps}
              alt={tab.iconProps.alt}
              className='text-brand-500'
            />
          ) : null;

          return (
            <SolidTab
              theme={
                isSticked ? ESolidTabTheme.Sticked : ESolidTabTheme.Ordinary
              }
              size={ESolidTabSize.Medium}
              key={tab.text}
              isActive={selectedTabId === tab.text}
              value={tab.text}
              label={tab.text}
              leadingIcon={
                icon &&
                tabsIconPosition !== EPartnersWidgetTabsIconPosition.Right
                  ? icon
                  : null
              }
              trailingIcon={
                icon &&
                tabsIconPosition === EPartnersWidgetTabsIconPosition.Right
                  ? icon
                  : null
              }
              onClick={onChangeTab}
            />
          );
        })}
      </div>
    </>
  );
};
