import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import { getSocialIcon, ImageWithPlaceholder } from '../../../index';
import { Card } from '../../atoms/Card';
import { Container } from '../../sections/Container';

export type TAuthorSummaryProps = {
  image: ImageProps | null;
  socials?: {
    type:
      | 'telegram'
      | 'linkedIn'
      | 'facebook'
      | 'youtube'
      | 'instagram'
      | 'twitter'
      | string;
    link: string;
  }[];
} & React.ComponentPropsWithoutRef<typeof Card>;

const AuthorSummary: React.FC<TAuthorSummaryProps> = props => {
  const { image, socials = [], ...card } = props;

  return (
    <Container>
      <div
        className={clsx(
          'flex w-full flex-col items-center gap-5 rounded-2xl bg-surface-100 px-8 pb-8 lg:flex-row lg:gap-11 lg:rounded-3xl lg:px-11  lg:pb-0 2xl:-mx-11 2xl:box-content',
        )}
      >
        {image && (
          <div className='w-full max-w-[18.875rem] self-center lg:shrink-0 lg:self-end'>
            {<ImageWithPlaceholder {...image} className='h-auto lg:w-full' />}
          </div>
        )}
        <div
          className={
            'flex w-full flex-col justify-start gap-6 lg:justify-between lg:py-8 2xl:flex-row'
          }
        >
          <div className='flex max-w-2xl flex-col gap-3 2xl:max-w-[38.75rem]'>
            {card?.tags && card.tags.length > 0 && (
              <div className='flex flex-wrap'>
                {card.tags.map((tag, index) => (
                  <span
                    key={index}
                    className='text-sm font-medium leading-relaxed text-brand-500 lg:text-base'
                  >
                    {tag.children}
                  </span>
                ))}
              </div>
            )}
            {card?.title && (
              <h2 className='text-2xl font-semibold leading-relaxed text-interface-1000 lg:text-4xl'>
                {card?.title}
              </h2>
            )}
            {card?.description && (
              <div className='font-normal text-interface-800'>
                {card?.description}
              </div>
            )}
          </div>

          {socials.length !== 0 && (
            <div className='flex gap-5 self-start text-control-600 lg:self-end'>
              {socials.map(s => (
                <a href={s.link} target='_blank' rel='noreferrer noopener'>
                  {getSocialIcon(
                    s.type,
                    'aspect-square h-4 w-4 object-contain object-center',
                  )}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default AuthorSummary;
