export enum EQuoteWithCardPaddings {
  Yes = 'Yes',
  No = 'No',
  OnlyBottom = 'OnlyBottom',
}

export enum EQuoteWithCardTheme {
  CardWithQuote1 = 'card_with_quote_1',
  CardWithQuote2 = 'card_with_quote_2',
}

export enum EQuoteWithCardIconSize {
  Xl = 'XL',
  Xl1 = '1XL',
}

export enum EQuoteWithCardStatsSize {
  L = 'L',
  M = 'M',
}
