import React, { useCallback, useEffect, useRef, useState } from 'react';

import { TSliderMouseEventsTypes } from './sliderMouseEvents.types';
import theme from '../../../tailwind.config.base';

const useSliderMouseEvents = (count: number): TSliderMouseEventsTypes => {
  const [clientWindow, setClientWindow] = useState<
    Window & typeof globalThis
  >();
  useEffect(() => {
    setClientWindow(window);
  }, []);
  const getFeatureCountByResolution = useCallback((): number => {
    const getResolution = (resolution: string): number => {
      return +resolution.replace(/[^0-9]/g, '');
    };

    const resolution = clientWindow?.innerWidth;

    if (!resolution) return 1;

    switch (true) {
      case resolution < getResolution(theme.screens.md):
        return 1;
      case resolution < getResolution(theme.screens.xl):
        return 2;
      case resolution < getResolution(theme.screens.lg):
        return 2;
      default:
        return 3;
    }
  }, [clientWindow]);

  const movementXRef = useRef(0);
  const activeRef = useRef(count % getFeatureCountByResolution());

  const handleMouseMove: { (event: MouseEvent): void } = (e: MouseEvent) => {
    const movementX = movementXRef.current;
    const activeElement = (e.currentTarget as HTMLElement)?.children[
      activeRef.current
    ];
    if (movementX > 0) {
      const prevElement = activeElement?.previousElementSibling;
      prevElement?.scrollIntoView({
        block: 'nearest',
        inline: 'center',
      });
    } else if (movementX < 0) {
      const nextElement = activeElement?.nextElementSibling;
      nextElement?.scrollIntoView({
        block: 'nearest',
        inline: 'center',
      });
    }
    movementXRef.current = e.movementX;
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    e.currentTarget.addEventListener('mousemove', handleMouseMove);
  };

  const handleMouseUp = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    e.currentTarget.removeEventListener('mousemove', handleMouseMove);
    if (
      movementXRef.current > 0 &&
      activeRef.current > count % getFeatureCountByResolution()
    ) {
      activeRef.current = activeRef.current - 1;
    } else if (
      movementXRef.current < 0 &&
      activeRef.current < count - getFeatureCountByResolution()
    ) {
      activeRef.current = activeRef.current + 1;
    }
    movementXRef.current = 0;
  };

  return {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onMouseLeave: handleMouseUp,
  };
};

export default useSliderMouseEvents;
