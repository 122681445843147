import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { Container } from '../../sections/Container';
import { DefaultCard, EDefaultCardLayout } from '../DefaultCard';

export type TVerticalCardsProps = {
  cards: React.ComponentProps<typeof DefaultCard>[];
  banner?: ImageProps | null;
  isBannerLeft?: boolean;
};

const VerticalCards: React.FC<TVerticalCardsProps> = props => {
  const { cards = [], banner, isBannerLeft } = props;

  return (
    <Container>
      <div
        className={clsx(
          'flex flex-col-reverse gap-4 lg:flex-row',
          isBannerLeft ? 'lg:flex-row-reverse' : 'lg:flex-row',
        )}
      >
        <div className='flex flex-col gap-4 lg:w-1/2 2xl:w-fit'>
          {cards.map(verticalCard => (
            <DefaultCard
              size={EDefaultCardLayout.DefaultCardWithBg}
              key={verticalCard?.title}
              title={verticalCard?.title}
              description={verticalCard?.description}
            />
          ))}
        </div>
        {banner && (
          <div className='relative h-auto w-auto overflow-hidden rounded-3xl bg-surface-100  lg:w-1/2 2xl:w-full 2xl:max-w-[52rem]  '>
            <ImageWithPlaceholder
              className={
                '!static object-contain object-center px-[1.813rem] py-[1.125rem] 2xl:px-[4rem] 2xl:py-[1.625rem]'
              }
              {...banner}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default VerticalCards;
