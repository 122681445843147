import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import { ETitleCardTheme } from './TitleCard.constants';
import { ImageWithPlaceholder } from '../ImageWithPlaceholder';

export type TTitleCardProps = {
  icon: ImageProps | null;
  title: string;
  cardTheme: ETitleCardTheme;
};

const TitleCard: React.FC<TTitleCardProps> = props => {
  const { icon, title, cardTheme = ETitleCardTheme.CardBg1 } = props;
  let titleColor = '';
  let cardBackgroundColor = '';

  switch (cardTheme) {
    case ETitleCardTheme.CardBg1:
      titleColor = 'text-surface-1000';
      cardBackgroundColor = 'bg-surface-100';
      break;
    case ETitleCardTheme.CardBg2:
      titleColor = 'text-surface-1000';
      cardBackgroundColor = 'bg-surface-50';
      break;
    case ETitleCardTheme.CardBg3:
      titleColor = 'text-surface-50';
      cardBackgroundColor = 'bg-brand-500';
      break;
    case ETitleCardTheme.CardBg4:
      titleColor = 'text-brand-1000';
      cardBackgroundColor = 'bg-accent-500';
      break;
    case ETitleCardTheme.CardBg5:
      titleColor = 'text-surface-50';
      cardBackgroundColor = 'bg-surface-800';
      break;
    case ETitleCardTheme.CardBg6:
      titleColor = 'text-surface-50';
      cardBackgroundColor = 'bg-surface-950';
      break;
    default:
      titleColor = 'text-surface-1000';
      cardBackgroundColor = 'bg-surface-100';
      break;
  }

  return (
    <div
      className={clsx(
        cardBackgroundColor,
        'flex h-full items-center gap-4 rounded-3xl p-[2rem]',
      )}
    >
      {icon && (
        <div className='h-[2.25rem] w-[2.25rem] shrink-0 overflow-hidden rounded-2xl'>
          <ImageWithPlaceholder
            width={36}
            height={36}
            {...icon}
            className={'h-full w-full object-cover object-center'}
          />
        </div>
      )}
      {title && (
        <h3
          className={clsx(titleColor, 'text-2xl font-semibold leading-relaxed')}
        >
          {title}
        </h3>
      )}
    </div>
  );
};

export default TitleCard;
