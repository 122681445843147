import clsx from 'clsx';
import React from 'react';

type TDescriptionProps = {
  description?: React.JSX.Element | string | null;
  className?: string;
};

export const Description: React.FC<TDescriptionProps> = ({
  description,
  className,
}) => {
  if (!description) {
    return null;
  }

  return (
    <div
      className={clsx(
        className,
        'text-base leading-mega-loose text-interface-800 transition empty:hidden lg:max-w-[472px]',
        'prose-a:text-brand-500 prose-a:hover:text-brand-550',
        'prose-p:pb-4 last:prose-p:pb-0',
        'prose-li:pb-2 last:prose-li:pb-0',
      )}
    >
      {description}
    </div>
  );
};
