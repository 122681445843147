export enum EHorizontalCardLayout {
  HorizontalCard = 'HorizontalCard',
  HorizontalCardWithBg = 'HorizontalCardWithBg',
}

export const enum EHorizontalCardBackground {
  White = 'White',
  Light = 'Light',
  Dark = 'Dark',
  Brand = 'Brand',
  Theme1 = 'Theme1', // light
  Theme2 = 'Theme2', // white
  Theme3 = 'Theme3', // brand
  Theme4 = 'Theme4', // accent
  Theme5 = 'Theme5', // dark
  Theme6 = 'Theme6', // black
}
