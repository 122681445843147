import clsx from 'clsx';
import { ImageProps } from 'next/image';
import React from 'react';

import { ETitleSize } from '../../atoms/Card';
import {
  CardTable,
  ECardTableLayoutSize,
  ECardTableMixedTheme,
} from '../../atoms/CardTable';
import { Container, EContainerVariant } from '../../sections/Container';

export type TCardTableMixedProps = {
  tablesLayout: {
    layoutSize: ECardTableLayoutSize;
    cards?: {
      title?: string;
      titleSize: ETitleSize;
      description?: React.JSX.Element | string | null;
      theme?: ECardTableMixedTheme;
      tableColumns?: {
        rows?: {
          icon?: ImageProps | null;
          eyebrow?: string;
          title?: string;
          description?: React.JSX.Element | string | null;
          valueTitle: string;
          valueDescription?: React.JSX.Element | string | null;
          id?: string;
        }[];
        id?: string;
      }[];
      id?: string;
    }[];
    id?: string;
  }[];
};

const CardTableMixed: React.FC<TCardTableMixedProps> = props => {
  const { tablesLayout = [] } = props;

  return (
    <Container variant={EContainerVariant.Default}>
      <div className='flex flex-col gap-4 xl:flex-row'>
        {tablesLayout.map((layout, i) => (
          <div
            key={i}
            className={clsx(
              'flex w-full flex-col gap-4',
              layout.layoutSize === ECardTableLayoutSize.Small && 'xl:w-1/3',
              layout.layoutSize === ECardTableLayoutSize.Half && 'xl:w-1/2',
              layout.layoutSize === ECardTableLayoutSize.Medium && 'xl:w-2/3',
              layout.layoutSize === ECardTableLayoutSize.Large && 'xl:w-full',
            )}
          >
            {layout.cards?.map((card, k) => <CardTable key={k} {...card} />)}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default CardTableMixed;
