import React from 'react';

import { Card, ETitleSize } from '../../atoms/Card';
import TitleCard, { TTitleCardProps } from '../../atoms/TitleCard/TitleCard';
import { Container } from '../../sections/Container';

export const enum EBannerCardWithTitleCardsTheme {
  Theme1 = 'Theme1', // white banner, light titles
  Theme2 = 'Theme2', // light banner, white titles
  Theme3 = 'Theme3', // brand banner, accent titles
  Theme4 = 'Theme4', // black banner, dark titles
  Theme5 = 'Theme5', // white banner, black titles
}

export type TBannerCardWithTitleCardsProps = {
  boxTitle: string;
  boxDescription?: string;
  boxSubtitle?: string;
  cards: TTitleCardProps[];
  theme?: EBannerCardWithTitleCardsTheme;
};

const BannerCardWithTitleCards: React.FC<
  TBannerCardWithTitleCardsProps
> = props => {
  const { boxTitle, boxDescription, boxSubtitle, cards, theme } = props;

  let themedCardStyles = undefined;
  if (theme === EBannerCardWithTitleCardsTheme.Theme4) {
    themedCardStyles = 'text-interface-50/80 prose-h3:text-interface-50';
  }

  return (
    <Container>
      <div className='grid grid-cols-1 items-center gap-12 xl:flex 2xl:gap-[4.5rem]'>
        <Card
          eyeBrowTitle={boxSubtitle}
          titleSize={ETitleSize.Large}
          title={boxTitle}
          description={boxDescription}
          className={themedCardStyles}
          cardTextColor={themedCardStyles ? null : undefined}
        />
        <div className='xl:flex xl:w-full xl:max-w-[38.75rem]'>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2 '>
            {cards.length !== 0 &&
              cards?.map(item => (
                <TitleCard
                  key={item.title}
                  title={item.title}
                  cardTheme={item?.cardTheme}
                  icon={item.icon}
                />
              ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BannerCardWithTitleCards;
